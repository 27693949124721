import graphql from "babel-plugin-relay/macro";
import { hyphenScoreToTitleCase } from "common/utils";
import {
  isNotNullOrUndefined,
  isNotNullOrUndefinedOrEmpty,
} from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import { PanelContextProvider } from "components/FullProgramming/common/PanelContext";
import ProgrammingConceptForm, {
  cardFormatListItemTemplateId,
  CARD_FORMAT_IDS,
} from "components/FullProgramming/common/ProgrammingConceptForm";
import { useParentRelayEnvironment } from "components/RelayEnvironmentCloneProvider";
import { useShowAlert } from "contexts/AlertsContext";
import { range } from "ramda";
import * as React from "react";
import { readInlineData, useMutation, useRelayEnvironment } from "react-relay";
import {
  createOperationDescriptor,
  RecordProxy,
  RecordSourceProxy,
} from "relay-runtime";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {
  asID,
  CardFormat,
  ControlSystem,
  fromCardFormatId,
  fromControlSystemId,
  fromCustomerId,
  idAsString,
  Panel,
  toCardFormatId,
  toGlobalId,
} from "securecom-graphql/client";
import ActiveConceptContext from "../common/ActiveConceptContext";
import { CardFormatContextProvider } from "../common/CardFormatFields/CardFormatContext";
import CardFormatNameField from "../common/CardFormatFields/CardFormatNameField";
import CardFormatNumberField from "../common/CardFormatFields/CardFormatNumberField";
import CardFormatRequireSiteCodeField from "../common/CardFormatFields/CardFormatRequireSiteCodeField";
import CardFormatSiteCode1Field from "../common/CardFormatFields/CardFormatSiteCode1Field";
import CardFormatSiteCode2Field from "../common/CardFormatFields/CardFormatSiteCode2Field";
import CardFormatSiteCode3Field from "../common/CardFormatFields/CardFormatSiteCode3Field";
import CardFormatSiteCode4Field from "../common/CardFormatFields/CardFormatSiteCode4Field";
import CardFormatSiteCode5Field from "../common/CardFormatFields/CardFormatSiteCode5Field";
import CardFormatSiteCode6Field from "../common/CardFormatFields/CardFormatSiteCode6Field";
import CardFormatSiteCode7Field from "../common/CardFormatFields/CardFormatSiteCode7Field";
import CardFormatSiteCode8Field from "../common/CardFormatFields/CardFormatSiteCode8Field";
import CardFormatSiteCodeLengthField from "../common/CardFormatFields/CardFormatSiteCodeLengthField";
import CardFormatSiteCodePositionField from "../common/CardFormatFields/CardFormatSiteCodePositionField";
import CardFormatTypeField from "../common/CardFormatFields/CardFormatTypeField";
import CardFormatUserCodeDigitsField from "../common/CardFormatFields/CardFormatUserCodeDigitsField";
import CardFormatUserCodeLengthField from "../common/CardFormatFields/CardFormatUserCodeLengthField";
import CardFormatUserCodePositionField from "../common/CardFormatFields/CardFormatUserCodePositionField";
import CardFormatWiegandCodeLengthField from "../common/CardFormatFields/CardFormatWiegandCodeLengthField";
import {
  listItemHasChanged,
  useChangedProgrammingConcept,
} from "../common/ChangedProgrammingConceptsContext";
import { useControlSystemFragment } from "../common/ControlSystemContext";
import {
  ProgrammingConceptSidebarButton,
  SaveErrors,
  SaveMutationHookResponse,
} from "../common/FullProgrammingForm";
import {
  RemountOnUpdateContainer,
  useResetLastUpdated,
} from "../common/LastUpdatedContext";
import { useOriginalControlSystem } from "../common/OriginalControlSystemContext";
import { useProgrammingActionsContext } from "../common/ProgrammingContext";
import { useTemplateContext } from "../common/TemplateContext";
import { useUncheckListItem } from "../Templates/utils";
import { removeListItemFromStore } from "../utils";
import {
  applyTemplateScalarDataToRecordProxy,
  indexRecordProxiesByNumber,
  selectPanelRecordProxy,
  toSortedListItemsArray,
} from "../utils/templates";
import { XRCardFormatsProgrammingConceptFormCardFormatDeleteMutation } from "./__generated__/XRCardFormatsProgrammingConceptFormCardFormatDeleteMutation.graphql";
import refreshMutationConcreteRequest, {
  XRCardFormatsProgrammingConceptFormCardFormatsRefreshMutation,
} from "./__generated__/XRCardFormatsProgrammingConceptFormCardFormatsRefreshMutation.graphql";
import {
  XRCardFormatsProgrammingConceptFormCardFormatsSendMutation,
  XRCardFormatsProgrammingConceptFormCardFormatsSendMutation$data,
} from "./__generated__/XRCardFormatsProgrammingConceptFormCardFormatsSendMutation.graphql";
import {
  XRCardFormatsProgrammingConceptFormInline_cardFormat$data,
  XRCardFormatsProgrammingConceptFormInline_cardFormat$key,
} from "./__generated__/XRCardFormatsProgrammingConceptFormInline_cardFormat.graphql";
import {
  XRCardFormatsProgrammingConceptFormInline_controlSystem$data,
  XRCardFormatsProgrammingConceptFormInline_controlSystem$key,
} from "./__generated__/XRCardFormatsProgrammingConceptFormInline_controlSystem.graphql";
import { XRCardFormatsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key } from "./__generated__/XRCardFormatsProgrammingConceptFormInline_xrProgrammingTemplateConcepts.graphql";
import { XRCardFormatsProgrammingConceptFormNavButton_controlSystem$key } from "./__generated__/XRCardFormatsProgrammingConceptFormNavButton_controlSystem.graphql";
import { XRCardFormatsProgrammingConceptForm_controlSystem$key } from "./__generated__/XRCardFormatsProgrammingConceptForm_controlSystem.graphql";

export const title = "Card Formats";
export const conceptId = "xr-card-formats";

export const getState = (
  controlSystem: XRCardFormatsProgrammingConceptFormInline_controlSystem$key
) =>
  readInlineData(
    graphql`
      fragment XRCardFormatsProgrammingConceptFormInline_controlSystem on ControlSystem
      @inline {
        id
        panel {
          id
          cardFormats {
            id
            number
            isNew
            ...XRCardFormatsProgrammingConceptFormInline_cardFormat
          }
        }
      }
    `,
    controlSystem
  );

export const getCardFormatState = (
  cardFormat: XRCardFormatsProgrammingConceptFormInline_cardFormat$key
) =>
  readInlineData(
    graphql`
      fragment XRCardFormatsProgrammingConceptFormInline_cardFormat on CardFormat
      @inline {
        id
        name
        type
        number
        isNew
        wiegandLength
        siteCodePosition
        siteCodeLength
        userCodePosition
        userCodeLength
        userCodeDigits
        requireSiteCode
        siteCodes
      }
    `,
    cardFormat
  );

const deleteMutation = graphql`
  mutation XRCardFormatsProgrammingConceptFormCardFormatDeleteMutation(
    $systemId: ID!
    $cardFormatNumber: String!
    $id: String!
  ) {
    removeCardFormat(
      systemId: $systemId
      cardFormatNumber: $cardFormatNumber
      id: $id
    ) {
      ... on RemoveCardFormatSuccessResponse {
        __typename
        deletedCardFormatId
        cardFormat {
          id
        }
      }
      ... on RemoveCardFormatDefaultError {
        error: type
      }
      ... on NotFoundError {
        error: type
      }
    }
  }
`;

const sendMutation = graphql`
  mutation XRCardFormatsProgrammingConceptFormCardFormatsSendMutation(
    $systemId: ID!
    $cardFormats: [CardFormatInput!]!
  ) {
    sendCardFormatsProgramming(systemId: $systemId, cardFormats: $cardFormats) {
      ... on Error {
        type
      }
      ... on SendCardFormatsProgrammingSuccessPayload {
        results {
          __typename
          ... on SendCardFormatsProgrammingCardFormatSuccessPayload {
            cardFormat {
              __typename
              id
              number
              ...XRCardFormatsProgrammingConceptFormInline_cardFormat
            }
          }
          ... on SendListItemsErrorPayload {
            number
            errors {
              __typename
              ... on InvalidInputError {
                type
                invalidField {
                  fieldName
                  reason
                }
              }
              ... on Error {
                type
              }
            }
          }
        }
      }
    }
  }
`;

const refreshMutation = graphql`
  mutation XRCardFormatsProgrammingConceptFormCardFormatsRefreshMutation(
    $systemId: ID!
  ) {
    refreshCardFormats(systemId: $systemId) {
      __typename
      ... on RefreshCardFormatsSuccessPayload {
        __typename
        controlSystem {
          ...XRCardFormatsProgrammingConceptFormInline_controlSystem
        }
      }
      ... on Error {
        error: type
      }
    }
  }
`;

const readCardFormatsTemplateData = (
  programmingTemplateConcepts: XRCardFormatsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key
) =>
  readInlineData(
    graphql`
      fragment XRCardFormatsProgrammingConceptFormInline_xrProgrammingTemplateConcepts on XrProgrammingTemplateConcepts
      @inline {
        cardFormats {
          id
          included
          number
          name {
            included
            data
          }
          type {
            included
            data
          }
          wiegandLength {
            included
            data
          }
          siteCodePosition {
            included
            data
          }
          siteCodeLength {
            included
            data
          }
          userCodePosition {
            included
            data
          }
          userCodeLength {
            included
            data
          }
          userCodeDigits {
            included
            data
          }
          requireSiteCode {
            included
            data
          }
          siteCode1 {
            included
            data
          }
          siteCode2 {
            included
            data
          }
          siteCode3 {
            included
            data
          }
          siteCode4 {
            included
            data
          }
          siteCode5 {
            included
            data
          }
          siteCode6 {
            included
            data
          }
          siteCode7 {
            included
            data
          }
          siteCode8 {
            included
            data
          }
        }
      }
    `,
    programmingTemplateConcepts
  ).cardFormats;

export function applyTemplateData(
  programmingTemplateConcepts: XRCardFormatsProgrammingConceptFormInline_xrProgrammingTemplateConcepts$key,
  controlSystemRecordProxy: RecordProxy<ControlSystem>,
  store: RecordSourceProxy
) {
  const panelRecordProxy = selectPanelRecordProxy(controlSystemRecordProxy);
  const cardFormatRecordProxies =
    panelRecordProxy.getLinkedRecords("cardFormats") ?? [];
  const cardFormatsByNumber = indexRecordProxiesByNumber(
    cardFormatRecordProxies
  );

  const cardFormatsTemplateData =
    readCardFormatsTemplateData(programmingTemplateConcepts) ?? [];

  cardFormatsTemplateData.forEach((cardFormatTemplateData) => {
    if (cardFormatTemplateData?.included) {
      let cardFormatRecordProxy = cardFormatsByNumber.get(
        cardFormatTemplateData.number
      ) as RecordProxy<CardFormat>;
      if (!cardFormatRecordProxy) {
        const newCardFormatId = applyNewCardFormatToCardFormatsList(
          {
            panel: {
              id: panelRecordProxy.getValue("id"),
              cardFormats: cardFormatRecordProxies.map((recordProxy) => ({
                number: recordProxy.getValue("number"),
              })),
              newCardFormat: panelRecordProxy.getLinkedRecord(
                "newCardFormat"
              ) && {
                id: panelRecordProxy
                  .getLinkedRecord("newCardFormat")
                  .getValue("id"),
              },
            },
            customer: {
              id: controlSystemRecordProxy
                .getLinkedRecord("customer")
                .getValue("id"),
            },
          },
          store
        );
        if (newCardFormatId) {
          cardFormatRecordProxy = store.get(
            newCardFormatId
          ) as RecordProxy<CardFormat>;
          if (cardFormatRecordProxy) {
            cardFormatRecordProxy.setValue(
              cardFormatTemplateData.number,
              "number"
            );
          }
        }
      }

      if (!cardFormatRecordProxy) {
        return;
      }

      applyTemplateScalarDataToRecordProxy(
        cardFormatRecordProxy,
        cardFormatTemplateData
      );

      cardFormatRecordProxy.setValue(
        Object.values({
          siteCode1: cardFormatTemplateData?.siteCode1?.included
            ? cardFormatTemplateData?.siteCode1?.data
            : null,
          siteCode2: cardFormatTemplateData?.siteCode2?.included
            ? cardFormatTemplateData?.siteCode2?.data
            : null,
          siteCode3: cardFormatTemplateData?.siteCode3?.included
            ? cardFormatTemplateData?.siteCode3?.data
            : null,
          siteCode4: cardFormatTemplateData?.siteCode4?.included
            ? cardFormatTemplateData?.siteCode4?.data
            : null,
          siteCode5: cardFormatTemplateData?.siteCode5?.included
            ? cardFormatTemplateData?.siteCode5?.data
            : null,
          siteCode6: cardFormatTemplateData?.siteCode6?.included
            ? cardFormatTemplateData?.siteCode6?.data
            : null,
          siteCode7: cardFormatTemplateData?.siteCode7?.included
            ? cardFormatTemplateData?.siteCode7?.data
            : null,
          siteCode8: cardFormatTemplateData?.siteCode8?.included
            ? cardFormatTemplateData?.siteCode8?.data
            : null,
        }),
        "siteCodes"
      );

      if (!cardFormatsByNumber.has(cardFormatTemplateData.number)) {
        cardFormatsByNumber.set(
          cardFormatTemplateData.number,
          cardFormatRecordProxy
        );
      }
    }
  });

  panelRecordProxy.setLinkedRecords(
    toSortedListItemsArray(cardFormatsByNumber),
    "cardFormats"
  );
}

const mergeOldAndNewCardFormats = (
  response: XRCardFormatsProgrammingConceptFormCardFormatsSendMutation$data,
  originalControlSystemData: XRCardFormatsProgrammingConceptFormInline_controlSystem$data
) => {
  if (response.sendCardFormatsProgramming.results) {
    const successfulCardFormats = response.sendCardFormatsProgramming.results
      .map((cardFormat) => {
        if (
          cardFormat.__typename ===
          "SendCardFormatsProgrammingCardFormatSuccessPayload"
        ) {
          return cardFormat;
        } else {
          return null;
        }
      })
      .filter(isNotNullOrUndefined)
      .flatMap((response) => response.cardFormat)
      .map(getCardFormatState);

    const mergedCardFormatsMap = new Map<
      string,
      XRCardFormatsProgrammingConceptFormInline_cardFormat$data
    >();

    originalControlSystemData.panel.cardFormats
      .map(getCardFormatState)
      .forEach((item) => mergedCardFormatsMap.set(item.id, item));

    successfulCardFormats.forEach((item) =>
      mergedCardFormatsMap.set(item.id, {
        ...mergedCardFormatsMap.get(item.id),
        ...item,
      })
    );

    return Array.from(mergedCardFormatsMap.values());
  } else {
    return [];
  }
};

const updateOriginalControlSystem = (
  response: XRCardFormatsProgrammingConceptFormCardFormatsSendMutation$data,
  originalControlSystemData: XRCardFormatsProgrammingConceptFormInline_controlSystem$data,
  parentRelayEnv: RelayModernEnvironment | null
) => {
  const mergedCardFormats = mergeOldAndNewCardFormats(
    response,
    originalControlSystemData
  );

  // Update original data store
  const operation = createOperationDescriptor(refreshMutationConcreteRequest, {
    id: originalControlSystemData.id,
  });

  if (parentRelayEnv) {
    parentRelayEnv.commitPayload(operation, {
      refreshCardFormats: {
        __typename: "RefreshCardFormatsSuccessPayload",
        controlSystem: {
          ...originalControlSystemData,
          panel: {
            ...originalControlSystemData.panel,
            cardFormats: mergedCardFormats,
          },
        },
      },
    });
  }
};

export const useSaveMutation = (props: {
  controlSystem: XRCardFormatsProgrammingConceptFormInline_controlSystem$key;
}): SaveMutationHookResponse => {
  const [sendCardFormats, isSendingCardFormats] =
    useMutation<XRCardFormatsProgrammingConceptFormCardFormatsSendMutation>(
      sendMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const changedCardFormats = useChangedProgrammingConcept(conceptId);
  const resetLastUpdated = useResetLastUpdated();
  const originalControlSystem = useOriginalControlSystem();

  return [
    async (showAlerts = false, isSavingAllListItems = false) =>
      new Promise((resolve, reject) => {
        const {
          id: systemId,
          panel: { cardFormats },
        } = getState(props.controlSystem);
        sendCardFormats({
          variables: {
            systemId: systemId,
            cardFormats: cardFormats
              .filter(
                (cardFormat) =>
                  cardFormat.isNew ||
                  (!!changedCardFormats &&
                    listItemHasChanged(cardFormat.id, changedCardFormats)) ||
                  isSavingAllListItems
              )
              .map(getCardFormatState)
              .map((cardFormat) => ({
                id: cardFormat.id,
                number: `${cardFormat.number}`,
                name: cardFormat.name,
                type: cardFormat.type,
                wiegandLength: Number(cardFormat?.wiegandLength),
                siteCodePosition: cardFormat.siteCodePosition,
                siteCodeLength: cardFormat.siteCodeLength,
                userCodePosition: cardFormat.userCodePosition,
                userCodeLength: cardFormat.userCodeLength,
                userCodeDigits: cardFormat.userCodeDigits,
                requireSiteCode: cardFormat.requireSiteCode,
                siteCodes: [...cardFormat.siteCodes].map((siteCode) =>
                  isNotNullOrUndefinedOrEmpty(siteCode)
                    ? Number(siteCode)
                    : null
                ),
                isNew: cardFormat.isNew,
              })),
          },
          onCompleted: (response) => {
            const saveErrors: SaveErrors = [];
            if (response.sendCardFormatsProgramming.type && showAlerts) {
              showAlert({
                type: "error",
                text: `Error Sending ${title} - Panel Not Found`,
              });
            } else if (response.sendCardFormatsProgramming.results) {
              response.sendCardFormatsProgramming.results.forEach(
                (response) => {
                  if (
                    response.__typename ===
                    "SendCardFormatsProgrammingCardFormatSuccessPayload"
                  ) {
                    resetLastUpdated(response.cardFormat.id);
                  } else if (
                    response.__typename === "SendListItemsErrorPayload"
                  ) {
                    saveErrors.push({
                      programmingConcept: title,
                      errors: response.errors,
                      listItemNumber: response.number,
                    });
                  }
                }
              );

              updateOriginalControlSystem(
                response,
                getState(originalControlSystem),
                parentRelayEnv
              );

              if (!saveErrors.length && showAlerts) {
                showAlert({
                  type: "success",
                  text: `Successfully Updated ${title}`,
                });
              }
            }
            resolve(saveErrors);
          },
          onError: () => {
            reject();
          },
        });
      }),
    isSendingCardFormats,
  ];
};

export const useRetrieveMutation = (props: {
  controlSystem: XRCardFormatsProgrammingConceptFormInline_controlSystem$key;
}): [(showAlerts: boolean) => Promise<void>, boolean] => {
  const [refreshCardFormats, isRefreshing] =
    useMutation<XRCardFormatsProgrammingConceptFormCardFormatsRefreshMutation>(
      refreshMutation
    );
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const resetLastUpdated = useResetLastUpdated();

  return [
    async (showAlerts: boolean) =>
      new Promise((resolve, reject) => {
        const { id: systemId } = getState(props.controlSystem);
        refreshCardFormats({
          variables: {
            systemId: systemId,
          },
          onCompleted: (response) => {
            const { controlSystem, error } = response.refreshCardFormats;
            if (controlSystem) {
              if (showAlerts) {
                showAlert({
                  type: "success",
                  text: "Card Format Programming Retrieved From the System",
                });
              }
              resetLastUpdated(conceptId);
              // Update original data store
              const operation = createOperationDescriptor(
                refreshMutationConcreteRequest,
                {
                  id: systemId,
                }
              );
              if (parentRelayEnv) {
                parentRelayEnv.commitPayload(operation, {
                  refreshCardFormats: {
                    __typename: response.refreshCardFormats.__typename,
                    controlSystem: getState(controlSystem),
                  },
                });
              }
              resolve();
            } else {
              if (showAlerts) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Retrieve Card Formats:${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Retrieve Card Formats",
                  });
                }
              }
              reject(error);
            }
          },
        });
      }),
    isRefreshing,
  ];
};

export function NavButton() {
  const [controlSystem] =
    useControlSystemFragment<XRCardFormatsProgrammingConceptFormNavButton_controlSystem$key>(
      graphql`
        fragment XRCardFormatsProgrammingConceptFormNavButton_controlSystem on ControlSystem {
          id
          panel {
            cardFormats {
              isNew
            }
          }
          customer {
            id
          }
        }
      `
    );
  const { cardFormats } = controlSystem.panel;
  const itemsCount = cardFormats.length;
  const hasNewItems = itemsCount > 0 && cardFormats.some(({ isNew }) => isNew);

  return (
    <ProgrammingConceptSidebarButton
      conceptId={conceptId}
      title={title}
      hasNewItems={hasNewItems}
      itemsCount={itemsCount}
    />
  );
}

export function Form() {
  const [controlSystem] =
    useControlSystemFragment<XRCardFormatsProgrammingConceptForm_controlSystem$key>(
      graphql`
        fragment XRCardFormatsProgrammingConceptForm_controlSystem on ControlSystem {
          id
          copiedCardFormat {
            id
          }
          customer {
            id
          }
          panel {
            ...PanelContext_panel
            ...PanelContextUseHardwareModel_panel
            ...CardFormatWiegandCodeLengthField_CardFormatList_panel
            ...CardFormatTypeField_CardFormatList_panel
            id
            helpFiles {
              programmingGuideUrl
              installGuideUrl
            }
            cardFormats {
              id
              number
              name
              isNew
              ...CardFormatContext_cardFormat
              ...CardFormatNumberField_cardFormat
              ...CardFormatNameField_cardFormat
              ...CardFormatTypeField_cardFormat
              ...CardFormatWiegandCodeLengthField_cardFormat
              ...CardFormatSiteCodePositionField_cardFormat
              ...CardFormatSiteCodeLengthField_cardFormat
              ...CardFormatUserCodePositionField_cardFormat
              ...CardFormatUserCodeLengthField_cardFormat
              ...CardFormatUserCodeDigitsField_cardFormat
              ...CardFormatRequireSiteCodeField_cardFormat
              ...CardFormatSiteCode1Field_cardFormat
              ...CardFormatSiteCode2Field_cardFormat
              ...CardFormatSiteCode3Field_cardFormat
              ...CardFormatSiteCode4Field_cardFormat
              ...CardFormatSiteCode5Field_cardFormat
              ...CardFormatSiteCode6Field_cardFormat
              ...CardFormatSiteCode7Field_cardFormat
              ...CardFormatSiteCode8Field_cardFormat
            }
            newCardFormat {
              id
              number
              name
              isNew
              ...CardFormatContext_cardFormat
              ...CardFormatNumberField_cardFormat
              ...CardFormatNameField_cardFormat
              ...CardFormatTypeField_cardFormat
              ...CardFormatWiegandCodeLengthField_cardFormat
              ...CardFormatSiteCodePositionField_cardFormat
              ...CardFormatSiteCodeLengthField_cardFormat
              ...CardFormatUserCodePositionField_cardFormat
              ...CardFormatUserCodeLengthField_cardFormat
              ...CardFormatUserCodeDigitsField_cardFormat
              ...CardFormatRequireSiteCodeField_cardFormat
              ...CardFormatSiteCode1Field_cardFormat
              ...CardFormatSiteCode2Field_cardFormat
              ...CardFormatSiteCode3Field_cardFormat
              ...CardFormatSiteCode4Field_cardFormat
              ...CardFormatSiteCode5Field_cardFormat
              ...CardFormatSiteCode6Field_cardFormat
              ...CardFormatSiteCode7Field_cardFormat
              ...CardFormatSiteCode8Field_cardFormat
            }
            systemOptions {
              ...SystemOptionsContextSystemType_systemOptions
            }
          }
        }
      `
    );
  const {
    panel: {
      cardFormats,
      newCardFormat,
      helpFiles: { programmingGuideUrl },
    },
  } = controlSystem;

  const [removeCardFormat, removingCardFormat] =
    useMutation<XRCardFormatsProgrammingConceptFormCardFormatDeleteMutation>(
      deleteMutation
    );

  const [selectedListItemId, setSelectedListItemId] = React.useState(
    controlSystem.panel.cardFormats[0]?.id ?? null
  );

  const totalCardFormatsMax = 8;

  const relayEnv = useRelayEnvironment();
  const uncheckListItem = useUncheckListItem()(CARD_FORMAT_IDS);
  const showAlert = useShowAlert();
  const parentRelayEnv = useParentRelayEnvironment();
  const { isEditing: templateIsEditing, isApplying } = useTemplateContext();
  const {
    isSavingAllProgramming,
    isSendingAllChanges,
    isSendingAllProgramming,
    isSendingConcept,
  } = useProgrammingActionsContext();
  const isSavingAll =
    isSavingAllProgramming ||
    isSendingAllChanges ||
    isSendingAllProgramming ||
    isSendingConcept;
  const [activeConcept] = React.useContext(ActiveConceptContext);

  const removeSelectedCardFormat = () => {
    if (selectedListItemId) {
      setSelectedListItemId(() => {
        const selectedIndex = controlSystem.panel.cardFormats.findIndex(
          (cardFormat) => cardFormat.id === selectedListItemId
        );
        const lastItemIsSelected =
          selectedIndex === controlSystem.panel.cardFormats.length - 1;
        const newSelectedIndex = lastItemIsSelected
          ? selectedIndex - 1
          : selectedIndex + 1;
        return controlSystem.panel.cardFormats[newSelectedIndex]?.id ?? null;
      });
      const cardFormat = cardFormats.find(
        (cardFormat) => cardFormat.id === selectedListItemId
      );
      uncheckListItem(String(cardFormat?.number));
      if (cardFormat?.isNew || templateIsEditing) {
        relayEnv.commitUpdate((store) => {
          removeListItemFromStore(
            selectedListItemId,
            "cardFormats",
            controlSystem.panel.id,
            store
          );
        });
      } else {
        const unSaltedId = idAsString(
          toGlobalId(
            "CardFormat",
            fromControlSystemId(asID(controlSystem.id)).systemId,
            cardFormat?.number ?? -1
          )
        );
        removeCardFormat({
          variables: {
            id: unSaltedId,
            systemId: controlSystem.id,
            cardFormatNumber: `${
              fromCardFormatId(asID(selectedListItemId)).cardNumber
            }`,
          },
          optimisticUpdater: (store) => {
            removeListItemFromStore(
              selectedListItemId,
              "cardFormats",
              controlSystem.panel.id,
              store
            );
          },
          updater: (store, response) => {
            const { deletedCardFormatId } = response.removeCardFormat;
            if (deletedCardFormatId) {
              showAlert({
                type: "success",
                text: "Card Format Deleted From the System",
              });
              removeListItemFromStore(
                selectedListItemId,
                "cardFormats",
                controlSystem.panel.id,
                store
              );
            }
          },
          onCompleted: (response) => {
            const { deletedCardFormatId, error } = response.removeCardFormat;
            if (deletedCardFormatId) {
              if (parentRelayEnv) {
                parentRelayEnv.commitUpdate((parentStore) => {
                  removeListItemFromStore(
                    selectedListItemId,
                    "cardFormats",
                    controlSystem.panel.id,
                    parentStore
                  );
                });
              }
            } else {
              if (
                response.removeCardFormat.__typename !==
                "RemoveCardFormatSuccessResponse"
              ) {
                if (error) {
                  showAlert({
                    type: "error",
                    text: `Unable to Delete Card Format: ${hyphenScoreToTitleCase(
                      error
                    )}`,
                  });
                } else {
                  showAlert({
                    type: "error",
                    text: "Unable to Delete Card Format",
                  });
                }
              }
            }
          },
          onError: () => {
            showAlert({
              type: "error",
              text: "Unable to Delete Card Format",
            });
          },
        });
      }
    }
  };

  return (
    <PanelContextProvider panel={controlSystem.panel}>
      <CardFormatContextProvider cardFormat={controlSystem.panel.cardFormats}>
        <ProgrammingConceptForm
          conceptId={conceptId}
          title={title}
          deleting={removingCardFormat}
          helpLink={`${programmingGuideUrl}#Card%Formats`}
          initialDataIsNotEmptyOrNull={isNotNullOrUndefined(
            controlSystem.panel.cardFormats
          )}
          isArrayConcept
          amountAvailable={totalCardFormatsMax - cardFormats.length}
          addButton={
            <ProgrammingConceptForm.AddButton
              onClick={() => {
                relayEnv.commitUpdate((store) => {
                  const newCardFormatId = applyNewCardFormatToCardFormatsList(
                    controlSystem,
                    store
                  );
                  if (newCardFormatId) setSelectedListItemId(newCardFormatId);
                });
              }}
            >
              Add Card Format
            </ProgrammingConceptForm.AddButton>
          }
        >
          {(conceptId === activeConcept || isApplying || isSavingAll) && (
            <ProgrammingConceptForm.ListItemsContainer>
              <ProgrammingConceptForm.ListItemPicker
                selectedId={selectedListItemId}
                onChange={(id) => {
                  setSelectedListItemId(id);
                }}
                newItemId={newCardFormat?.id}
                items={cardFormats.map((cardFormat) => ({
                  id: cardFormat.id,
                  templateListItemId: cardFormatListItemTemplateId(
                    cardFormat.number
                  ),
                  isnew: cardFormat.isNew,
                  label: `#${cardFormat.number} ${cardFormat.name}`,
                }))}
              />
              <ProgrammingConceptForm.SelectedItemsContainer
                selectedListItemId={selectedListItemId}
                setSelectedListItemId={setSelectedListItemId}
              >
                {cardFormats.map((cardFormat) => (
                  <RemountOnUpdateContainer nodeId={cardFormat.id}>
                    <ProgrammingConceptForm.SelectedItem
                      conceptId={conceptId}
                      key={cardFormat.id}
                      isnew={cardFormat.isNew}
                      visible={cardFormat.id === selectedListItemId}
                      listItemId={cardFormat.id}
                      templateListItemId={cardFormatListItemTemplateId(
                        cardFormat.number
                      )}
                      title={`# ${cardFormat.number} ${cardFormat.name}`}
                      onDuplicate={
                        totalCardFormatsMax - cardFormats.length > 0 &&
                        (() => {
                          relayEnv.commitUpdate((store) => {
                            const duplicateId =
                              applyDuplicatedCardFormatToCardFormatsList(
                                selectedListItemId,
                                controlSystem,
                                store
                              );
                            if (duplicateId) {
                              setSelectedListItemId(duplicateId);
                            }
                          });
                        })
                      }
                      onCopy={() => {
                        relayEnv.commitUpdate((store) => {
                          const controlSystemRecord = store.get(
                            controlSystem.id
                          );
                          const cardFormatRecord =
                            store.get<CardFormat>(selectedListItemId);
                          if (controlSystemRecord && cardFormatRecord) {
                            const tempRecord =
                              store.get("copiedCardFormat") ??
                              store.create("copiedCardFormat", "CardFormat");
                            tempRecord.copyFieldsFrom(cardFormatRecord);
                            controlSystemRecord.setLinkedRecord(
                              tempRecord,
                              "copiedCardFormat"
                            );
                          }
                        });
                      }}
                      onPaste={
                        !!controlSystem.copiedCardFormat &&
                        (() => {
                          relayEnv.commitUpdate((store) => {
                            const cardFormatRecord =
                              store.get<CardFormat>(selectedListItemId);
                            const copiedCardFormatRecord =
                              store.get<CardFormat>("copiedCardFormat");
                            if (cardFormatRecord && copiedCardFormatRecord) {
                              applyCardFormatProgrammingToCardFormat(
                                copiedCardFormatRecord,
                                cardFormatRecord
                              );
                            }
                          });
                        })
                      }
                      onRemove={removeSelectedCardFormat}
                    >
                      <CardFormatContextProvider cardFormat={cardFormat}>
                        <ProgrammingConceptForm.Fields key={cardFormat.id}>
                          <CardFormatNumberField />
                          <CardFormatNameField />
                          <CardFormatTypeField />
                          <CardFormatWiegandCodeLengthField />
                          <CardFormatSiteCodePositionField />
                          <CardFormatSiteCodeLengthField />
                          <CardFormatUserCodePositionField />
                          <CardFormatUserCodeLengthField />
                          <CardFormatUserCodeDigitsField />
                          <CardFormatRequireSiteCodeField />
                          <CardFormatSiteCode1Field />
                          <CardFormatSiteCode2Field />
                          <CardFormatSiteCode3Field />
                          <CardFormatSiteCode4Field />
                          <CardFormatSiteCode5Field />
                          <CardFormatSiteCode6Field />
                          <CardFormatSiteCode7Field />
                          <CardFormatSiteCode8Field />
                        </ProgrammingConceptForm.Fields>
                      </CardFormatContextProvider>
                    </ProgrammingConceptForm.SelectedItem>
                  </RemountOnUpdateContainer>
                ))}
              </ProgrammingConceptForm.SelectedItemsContainer>
            </ProgrammingConceptForm.ListItemsContainer>
          )}
        </ProgrammingConceptForm>
      </CardFormatContextProvider>
    </PanelContextProvider>
  );
}

const applyDuplicatedCardFormatToCardFormatsList = (
  cardFormatId: string,
  controlSystem: {
    readonly panel: Parameters<typeof getNextAvailableNumber>[0] & {
      readonly id: string;
      readonly newCardFormat: {
        readonly id: string;
      } | null;
    };
    readonly customer: {
      readonly id: string;
    };
  },
  store: RecordSourceProxy
) => {
  const { id, newCardFormat } = controlSystem.panel;
  const panelRecord = store.get<Panel>(id);
  const cardFormatRecord = store.get<CardFormat>(cardFormatId);
  const { id: globalCustomerId } = controlSystem.customer;
  const { customerId } = fromCustomerId(asID(globalCustomerId));
  if (panelRecord && cardFormatRecord) {
    const newCardFormatRecord = panelRecord.getLinkedRecord("newCardFormat");
    if (newCardFormat) {
      const nextNumber = getNextAvailableNumber(controlSystem.panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromCardFormatId(
          asID(newCardFormatRecord.getDataID())
        );
        const nextNewCardFormatId = idAsString(
          toCardFormatId(customerId, systemId, nextNumber)
        );
        const nextNewCardFormat = store.create(
          nextNewCardFormatId,
          "CardFormat"
        ) as RecordProxy<CardFormat>;
        nextNewCardFormat.copyFieldsFrom(newCardFormatRecord);
        nextNewCardFormat.setValue(nextNewCardFormatId, "id");
        nextNewCardFormat.setValue(String(nextNumber), "number");
        nextNewCardFormat.setValue(true, "isNew");

        const duplicatedCardFormatRecord =
          applyCardFormatProgrammingToCardFormat(
            cardFormatRecord,
            nextNewCardFormat
          );
        const cardFormatRecords =
          panelRecord.getLinkedRecords("cardFormats") ?? [];
        panelRecord.setLinkedRecords(
          [...cardFormatRecords, duplicatedCardFormatRecord],
          "cardFormats"
        );

        return duplicatedCardFormatRecord.getValue("id");
      }
    }
  }
};

const applyCardFormatProgrammingToCardFormat = (
  source: RecordProxy<CardFormat>,
  dest: RecordProxy<CardFormat>
) => {
  const id = dest.getValue("id");
  const number = dest.getValue("number");
  dest.copyFieldsFrom(source);
  dest.setValue(id, "id");
  dest.setValue(number, "number");
  return dest;
};

const applyNewCardFormatToCardFormatsList = (
  controlSystem: {
    readonly panel: Parameters<typeof getNextAvailableNumber>[0] & {
      readonly id: string;
      readonly newCardFormat: {
        readonly id: string;
      } | null;
    };
    readonly customer: {
      readonly id: string;
    };
  },
  store: RecordSourceProxy
) => {
  const { id, newCardFormat } = controlSystem.panel;
  const { id: globalCustomerId } = controlSystem.customer;
  const { customerId } = fromCustomerId(asID(globalCustomerId));
  const panelRecord = store.get<Panel>(id);
  if (panelRecord) {
    const newCardFormatRecord = panelRecord.getLinkedRecord("newCardFormat");
    if (newCardFormat) {
      const nextNumber = getNextAvailableNumber(controlSystem.panel);
      if (isNotNullOrUndefined(nextNumber)) {
        const { systemId } = fromCardFormatId(
          asID(newCardFormatRecord.getDataID())
        );
        const nextNewCardFormatId = idAsString(
          toCardFormatId(customerId, systemId, nextNumber)
        );
        const nextNewCardFormat = store.create(
          nextNewCardFormatId,
          "CardFormat"
        ) as RecordProxy<CardFormat>;
        nextNewCardFormat.copyFieldsFrom(newCardFormatRecord);
        nextNewCardFormat.setValue(nextNewCardFormatId, "id");
        nextNewCardFormat.setValue(String(nextNumber), "number");
        nextNewCardFormat.setValue(true, "isNew");
        const cardFormatsRecords =
          panelRecord.getLinkedRecords("cardFormats") ?? [];
        panelRecord.setLinkedRecords(
          [...cardFormatsRecords, nextNewCardFormat],
          "cardFormats"
        );

        return nextNewCardFormat.getValue("id");
      }
    }
  }
};

const getNextAvailableNumbers = (panel: {
  readonly cardFormats: ReadonlyArray<{
    readonly number: string;
  }>;
}) => {
  const allPossibleNumbers = new Set(range(1, 9));

  const takenNumbers = new Set(
    panel.cardFormats
      .filter(Boolean)
      .map((cardFormat) => Number(cardFormat?.number))
  );
  const availableNumbers = setDifference(
    takenNumbers,
    allPossibleNumbers
  ) as Set<number>;

  return availableNumbers;
};

const getNextAvailableNumber = (
  panel: Parameters<typeof getNextAvailableNumbers>[0]
) => setFirst(getNextAvailableNumbers(panel));
