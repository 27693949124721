// Arrow.tsx
import React from "react";
import styled, { keyframes } from "styled-components";
// Keyframes for inward movement

// optional animation for region arrows
const RegionalArrowAnimation = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(var(--x), var(--y));
  }
  100% {
    transform: translate(0, 0);
  }
`;

const LineArrowAnimation = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: .8;
  }
    100% {
    transform: translate(var(--x), var(--y));
    opacity: 0;
  }
`;

export const LineArrow = styled.polygon`
  fill: orange;
  animation: ${LineArrowAnimation} 1.8s infinite;
  transform-origin: 50% 50%;
`;

export const InwardRegionArrow = styled.polygon`
  fill: orange;
`;
export const OutwardRegionArrow = styled.polygon`
  fill: orange;
`;

export const RegionArrowInwards: React.FC = () => {
  const triangleSide = 20;
  const baseWidth = 30;
  const radius = 12 + triangleSide / 2;
  const centerX = 100;
  const centerY = 70;
  const triangleHeight = (Math.sqrt(3) / 2) * triangleSide;
  return (
    <>
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="white"
        stroke="white"
      />
      <InwardRegionArrow
        points={`
         ${centerX - baseWidth / 2},${centerY - triangleHeight / 3}  
         ${centerX + baseWidth / 2},${centerY - triangleHeight / 3}  
         ${centerX},${centerY + (2 * triangleHeight) / 3}  
       `}
        style={{ "--x": "0px", "--y": "10px" } as React.CSSProperties} // Move animation inward
      />
    </>
  );
};
// Styled polygon with animation
export const RegionArrowOutwards: React.FC = () => {
  const triangleSide = 20;
  const baseWidth = 30; // Smaller base width
  const radius = 12 + triangleSide / 2;
  const centerX = 100;
  const centerY = 70;
  // Calculate the height of the equilateral triangle
  const triangleHeight = (Math.sqrt(3) / 2) * triangleSide;

  return (
    <>
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="white"
        stroke="white"
      />
      <OutwardRegionArrow
        points={`
          ${centerX - baseWidth / 2},${centerY + triangleHeight / 3}
          ${centerX + baseWidth / 2},${centerY + triangleHeight / 3}
          ${centerX},${centerY - (2 * triangleHeight) / 3}
        `}
        style={{ "--x": "0px", "--y": "-10px" } as React.CSSProperties}
        fill="orange"
        stroke="orange"
      />
    </>
  );
};

export const OutwardLineArrows: React.FC = () => (
  <LineArrow
    style={{ "--x": "20px", "--y": "0px" } as React.CSSProperties}
    points="150,100 130,200 130,0"
  />
);

export const InwardLineArrows: React.FC = () => (
  <LineArrow
    style={{ "--x": "-20px", "--y": "0px" } as React.CSSProperties}
    points="50,100 70,0 70,200"
  />
);

export const BiDirectionalArrows: React.FC = () => {
  const triangleSide = 15; // Reduced size to fit within the circle
  const baseWidth = 20;
  const radius = 12 + triangleSide / 2;
  const centerX = 100;
  const centerY = 70;
  const spaceBetweenArrows = 4; // Creates space between base-to-base arrows
  const triangleHeight = (Math.sqrt(3) / 2) * triangleSide;

  return (
    <>
      {/* Circle */}
      <circle
        cx={centerX}
        cy={centerY}
        r={radius}
        fill="white"
        stroke="white"
      />
      {/* Outward Arrow (Pointing Up) */}
      <OutwardRegionArrow
        points={`
         ${centerX - baseWidth / 2},${centerY - spaceBetweenArrows}  
         ${centerX + baseWidth / 2},${centerY - spaceBetweenArrows}  
         ${centerX},${centerY - (triangleHeight + spaceBetweenArrows)}  
       `}
        style={{ "--x": "0px", "--y": "-5px" } as React.CSSProperties} // Upward motion
      />
      {/* Inward Arrow (Pointing Down) */}
      <InwardRegionArrow
        points={`
         ${centerX - baseWidth / 2},${centerY + spaceBetweenArrows}  
         ${centerX + baseWidth / 2},${centerY + spaceBetweenArrows}  
         ${centerX},${centerY + (triangleHeight + spaceBetweenArrows)}  
       `}
        style={{ "--x": "0px", "--y": "5px" } as React.CSSProperties} // Downward motion
      />
    </>
  );
};
export const BiDirectionalLineArrows: React.FC = () => (
  <>
    <LineArrow
      style={{ "--x": "-20px", "--y": "0px" } as React.CSSProperties}
      points="50,100 70,0 70,200"
    />{" "}
    {/* Rightward pointing to center */}
    <LineArrow
      style={{ "--x": "20px", "--y": "0px" } as React.CSSProperties}
      points="150,100 130,200 130,0"
    />{" "}
    {/* Leftward pointing to center */}
  </>
);
