import { sleep } from "common/utils/universal/promise";
import { panelVersionGTOE } from "components/FullProgramming/utils/panel";
import { isNil } from "ramda";
import { between } from "utils";

/**
 * @ngdoc object
 * @name App.controller:ProgrammingRouterCtrl
 * @function
 * @requires $scope
 * @requires $rootScope
 * @requires $state
 * @requires $stateParams
 * @requires UserService
 * @requires Panel
 * @requires PANEL_CONCEPTS
 * @requires ControlSystemsService
 * @requires PanelDefinitionService
 * @requires PanelProgrammingService
 * @requires $modal
 *
 *
 * @description
 *  Controller for the Programming screen.  Supports only CellCom in this version.
 *
 *  Panel.concepts
 */
App.controller("ProgrammingRouterCtrl", [
  "$rootScope",
  "$scope",
  "$q",
  "$http",
  "$state",
  "$stateParams",
  "UserService",
  "Panel",
  "PANEL_CONCEPTS",
  "ERROR_CODES",
  "ControlSystemsService",
  "PanelProgrammingService",
  "$modal",
  "$filter",
  "PROGRAMMING_GUIDE",
  "InitialConnectionService",
  "PanelDefRuleService",
  "OnlinePanelService",
  "ReplacePanelService",
  "PanelCapabilitiesService",
  "ClientEventsService",
  "VALIDATION_PATTERNS",
  "PanelProgArchiveService",
  "PanelDefinitionService",
  "PanelTestService",
  "PanelTest",
  "JobSchedulerService",
  "UserSettingsService",
  "GoogleAnalyticsService",
  function (
    $rootScope,
    $scope,
    $q,
    $http,
    $state,
    $stateParams,
    UserService,
    Panel,
    PANEL_CONCEPTS,
    ERROR_CODES,
    ControlSystemsService,
    PanelProgrammingService,
    $modal,
    $filter,
    PROGRAMMING_GUIDE,
    InitialConnectionService,
    PanelDefRules,
    OnlinePanelService,
    ReplacePanelService,
    PanelCapabilitiesService,
    ClientEventsService,
    VALIDATION_PATTERNS,
    PanelProgArchiveService,
    PanelDefinitionService,
    PanelTestService,
    PanelTest,
    JobSchedulerService,
    UserSettingsService,
    GoogleAnalyticsService
  ) {
    const PRE_PROGRAM_TEMPLATE_NAME = "PRE_PROGRAM_TEMPLATE";
    const AUTO_PROGRAM_TEMPLATE_FAILURE_MESSAGE =
      "Unable to apply the entire template.  Please check the programming, some fields may not have been programmed correctly.";
    $scope.lncWiredZonesAvailable = lncWiredZonesAvailable;
    $scope.lncWiredZoneAvailableCount = lncWiredZoneAvailableCount;
    $scope.PANEL_CONCEPTS = PANEL_CONCEPTS;
    $scope.ERROR_CODES = ERROR_CODES;
    $scope.PROGRAMMING_GUIDE = PROGRAMMING_GUIDE;
    UserService.customer_id = $stateParams.customer_id;
    UserService.control_system_id = $stateParams.control_system_id;
    UserService.panel_id = $stateParams.panel_id;
    $scope.UserService = UserService;
    $scope.validVernaculars = UserService.dealerInfo.vernaculars;
    $scope.unableToLoadPanel = false;
    $scope.showSpinner = false;
    $scope.connectStatus = "";
    $scope.connectError = null;
    $scope.userAccount = "";
    $scope.userRemoteKey = "";
    $scope.selectedJumpItem = "communication";
    PanelProgrammingService.panel_id = UserService.panel_id;
    $scope.conceptsWithDisplay = [];
    $scope.PanelsOpen = [];
    $scope.selectedTab = 0;
    $scope.search = {};
    $scope.search.zone = "";
    $scope.search.area = "";
    $scope.search.device = "";
    $scope.search.output = "";
    $scope.search.keyfob = "";
    $scope.ClientEventsService = ClientEventsService;
    $scope.OnlinePanelService = OnlinePanelService;
    $scope.VALIDATION_PATTERNS = VALIDATION_PATTERNS;
    $scope.devices = {};
    $scope.has1100T = false;
    $scope.supports1100T = false;
    $scope.comp_wireless_hex_sn = false;
    $scope.isRestoringFromBackup = false;
    $scope.searchZones = function (row) {
      if (
        !$scope.Panel[PANEL_CONCEPTS.zone_informations.api_name] ||
        !row ||
        !row.name
      )
        return true;
      var zoneTypes = $scope.Panel.getFieldValues(
        PANEL_CONCEPTS.zone_informations.api_name,
        "tipe"
      );
      var zoneDisplay = $filter("filter")(zoneTypes, function (z) {
        return z.val === row.tipe;
      });
      var zoneTypeDisplay =
        zoneDisplay.length > 0 ? zoneDisplay[0].display : "";
      return (
        angular
          .lowercase(row.name)
          .indexOf(angular.lowercase($scope.search.zone) || "") !== -1 ||
        angular
          .lowercase(row.number)
          .indexOf(angular.lowercase($scope.search.zone) || "") !== -1 ||
        angular
          .lowercase(zoneTypeDisplay)
          .indexOf(angular.lowercase($scope.search.zone) || "") !== -1
      );
    };

    deviceIsNetworkXT = (device) => device.network === "Y";

    $scope.disableNetworkToggle = function (curDevice) {
      if ($scope.Panel[PANEL_CONCEPTS.communication.api_name].com_type !== "1")
        return false;

      let networkDevice = $scope.Panel[
        PANEL_CONCEPTS.device_informations.api_name
      ].find((device) => deviceIsNetworkXT(device));

      return networkDevice && curDevice.number !== networkDevice.number;
    };

    $scope.searchAreas = function (row) {
      if (
        !$scope.Panel[PANEL_CONCEPTS.area_informations.api_name] ||
        !row ||
        !row.name
      )
        return true;
      return (
        angular
          .lowercase(row.name)
          .indexOf(angular.lowercase($scope.search.area) || "") !== -1 ||
        angular
          .lowercase(row.number)
          .indexOf(angular.lowercase($scope.search.area) || "") !== -1
      );
    };
    $scope.searchDevices = function (row) {
      if (
        !$scope.Panel[PANEL_CONCEPTS.device_informations.api_name] ||
        !row ||
        !row.name
      )
        return true;
      return (
        angular
          .lowercase(row.name)
          .indexOf(angular.lowercase($scope.search.device) || "") !== -1 ||
        angular
          .lowercase(row.number)
          .indexOf(angular.lowercase($scope.search.device) || "") !== -1
      );
    };
    $scope.searchOutputs = function (row) {
      if (
        !$scope.Panel[PANEL_CONCEPTS.output_informations.api_name] ||
        !row ||
        !row.name
      )
        return true;
      return (
        angular
          .lowercase(row.name)
          .indexOf(angular.lowercase($scope.search.output) || "") !== -1 ||
        angular
          .lowercase(row.number)
          .indexOf(angular.lowercase($scope.search.output) || "") !== -1
      );
    };
    $scope.searchFobs = function (row) {
      if (!$scope.Panel[PANEL_CONCEPTS.keyfobs.api_name] || !row || !row.number)
        return true;
      return (
        angular
          .lowercase(row.number)
          .indexOf(angular.lowercase($scope.search.keyfob) || "") !== -1 ||
        angular
          .lowercase(row.user_num)
          .indexOf(angular.lowercase($scope.search.keyfob) || "") !== -1 ||
        angular
          .lowercase(row.kf_serial)
          .indexOf(angular.lowercase($scope.search.keyfob) || "") !== -1
      );
    };

    $scope.doorSupportsWireless = function () {
      return PanelCapabilitiesService.canHaveWirelessDoors(
        $scope.controlSystem.panels[0]
      );
    };

    $scope.panelSupports1168 = function () {
      return PanelCapabilitiesService.supports1168(
        $scope.controlSystem.panels[0]
      );
    };

    $scope.panelSupportsPrivateDoors = function () {
      return PanelCapabilitiesService.supportsPrivateDoors(
        $scope.controlSystem.panels[0]
      );
    };

    $scope.panelSupportsZoneExpander = function () {
      return PanelCapabilitiesService.supportsZoneExpander(
        $scope.controlSystem.panels[0]
      );
    };

    $scope.panelSupportsECPZones = function () {
      return PanelCapabilitiesService.supportsECPZones(
        $scope.controlSystem.panels[0].hardware_model,
        $scope.controlSystem.panels[0].software_version
      );
    };

    $scope.zoneIsECPZone = function (zoneNumber) {
      return (
        $scope.Panel.system_options.kpad_input === "E" && +zoneNumber < 201
      );
    };

    $scope.panelSupportsDSCZones = function () {
      return PanelCapabilitiesService.supportsDSCZones(
        $scope.controlSystem.panels[0].hardware_model,
        $scope.controlSystem.panels[0].software_version
      );
    };

    $scope.zoneIsDSCZone = function (zoneNumber) {
      return (
        $scope.Panel.system_options.kpad_input === "D" && +zoneNumber < 201
      );
    };

    $scope.supportsNetworkKeypad = () =>
      PanelCapabilitiesService.supportsNetworkKeypad(
        $scope.controlSystem.panels[0]
      );

    $scope.panelHasAutoProgramEnabled = () =>
      $scope.controlSystem?.panels?.[0]?.auto_program ?? false;

    /**
     * Pre-Programming comes back as the inverse of online in the panel.
     * The logic is, if the panel is "offline" it is being pre-programmed.
     */
    $scope.panelHasPreProgramEnabled = () =>
      !$scope.controlSystem?.panels?.[0]?.online ?? false;

    $scope.panelIsAtLeastFirmwareVersion = (firmware) =>
      Number($scope.controlSystem?.panels?.[0]?.software_version ?? 0) >=
      Number(firmware);

    const supports1100T = () =>
      PanelCapabilitiesService.supports1100T($scope.controlSystem.panels[0]);

    const panelHas1100T = (deviceInformations) => {
      if (deviceInformations) {
        return deviceInformations.some(
          (device) =>
            device.tipe === "7" || device.wireless_translator_1100t === "Y"
        );
      } else {
        return false;
      }
    };

    const competitorWirelessUsesHex = (deviceInformations) => {
      return deviceInformations
        ? deviceInformations.some((device) =>
            ["2", "3"].includes(device.wireless_translator_1100t_frequency)
          )
        : false;
    };

    // SCAPI won't accept an empty string for this field, so we need to set it to the default, which is 0000000000
    $scope.nullCheckExpanderSerialNumber = (serialNumber, zoneNumber) => {
      if (isNil(serialNumber) || serialNumber === "") {
        $scope.Panel.zone_informations[
          $scope.Panel.zone_informations.findIndex(
            (zone) => zone.number === zoneNumber
          )
        ].expander_serial_number = "0000000000";
      }
    };

    const zoneNumberSupportsExpanderSerialNumber = (zoneNumber) => {
      if ($scope.Panel.isXR550Family($scope.Panel.panel_model)) {
        return !between(Number(zoneNumber), 0, 10);
      } else {
        return !(
          between(Number(zoneNumber), 0, 10) ||
          Number(zoneNumber) === 80 ||
          between(Number(zoneNumber), 85, 99)
        );
      }
    };

    const panelTypeSupportsExpanderSerialNumber = () =>
      !["XTLP", "CellComSL", "CellComEX", "iComSL", "DualCom"].includes(
        $scope.Panel.panel_model
      );

    const panelVersionSupportsExpanderSerialNumber = () =>
      between(Number($scope.Panel.panel_version), 213, 599) ||
      between(Number($scope.Panel.panel_version), 713, Infinity);

    $scope.canShowExpanderSerialNumber = (zoneNumber) => {
      return (
        panelVersionSupportsExpanderSerialNumber() &&
        panelTypeSupportsExpanderSerialNumber() &&
        zoneNumberSupportsExpanderSerialNumber(zoneNumber)
      );
    };

    const panelVersionSupportsCustomDnsServerField = () =>
      between(Number($scope.Panel.panel_version), 213, 599) ||
      between(Number($scope.Panel.panel_version), 713, Infinity);

    const panelTypeSupportsCustomDnsServerField = () =>
      $scope.Panel.isXR550Family($scope.Panel.panel_model);

    const connectionTypeSupportsCustomDnsServerField = () => {
      return !$scope.Panel.communication_paths.some(
        (path) => path.comm_type === "W"
      );
    };

    $scope.useCustomDnsServerField = (field) => {
      return (
        field === "dns_server" &&
        panelTypeSupportsCustomDnsServerField() &&
        panelVersionSupportsCustomDnsServerField() &&
        connectionTypeSupportsCustomDnsServerField()
      );
    };

    $scope.setPanelHas1100T = () =>
      ($scope.has1100T = panelHas1100T($scope.Panel.device_informations));

    $scope.Forms = {};

    $scope.$on("all_concepts_retrieved", function () {
      runForeignConceptRules();
      createArrayConceptWatchers();
      armModeWatchData.armModeLoaded =
        $scope.Panel.concepts.includes("system_options");
      armModeWatchData.areasLoaded =
        $scope.Panel.concepts.includes("area_informations");
      generateDefaultAreas(false);
    });

    $scope.panelSupportsVplex = () =>
      PanelCapabilitiesService.supportsVplex($scope.controlSystem.panels[0]);

    /**
     * This function runs rules that do not pertain to a specific item.
     * example: The use on-board 1100 wireless in system options for XT changes the number of zones available, this must
     * be done when the controller loads to show the correct number of zones available. Rules for
     * specific items will be run when the item is opened (via da-panel-entity)
     */
    var runForeignConceptRules = function () {
      // For each full programming concept
      angular.forEach(
        $scope.Panel.fullProgrammingConceptsWithDisplay,
        function (concept) {
          // For each field in the concept
          angular.forEach(
            $scope.Panel.PDS.panel_def.CONCEPTS[concept.key],
            function (fieldDef, fieldKey) {
              // If there are rules for that field
              if (
                angular.isDefined(
                  $scope.Panel.session.panelDefRules.referenceGroups[
                    concept.key
                  ]
                ) &&
                angular.isDefined(
                  $scope.Panel.session.panelDefRules.referenceGroups[
                    concept.key
                  ][fieldKey]
                )
              ) {
                // Process the override field rules
                processForeignConceptRules(
                  concept.key,
                  fieldKey,
                  "overrideFieldRules"
                );
                // note: Don't run default rules since we want to show the values we receive from SCAPI, as they are
                // Then process the hide rules
                processForeignConceptRules(concept.key, fieldKey, "hideRules");
                // Then process the disable rules
                processForeignConceptRules(
                  concept.key,
                  fieldKey,
                  "disableRules"
                );
              }
            }
          );
        }
      );
    };

    /**
     * Runs rules for which the concept of the reference value of the rule is different from the concept for the
     * target field for the rule. For example, changing house code in system options from 0 to not 0 may show or hide
     * fields in zone informations, devices, etc.
     *
     * @param conceptKey {string} name of the concept (ex: 'zone_informations')
     * @param fieldKey {string} the name of the field (ex: 'comm_type')
     * @param rules {array} group of panel def rules to process
     */
    var processForeignConceptRules = function (conceptKey, fieldKey, rules) {
      if (
        $scope.Panel.session.panelDefRules.referenceGroups[conceptKey][
          fieldKey
        ][rules].length > 0
      ) {
        var foreignConceptsRules =
          $scope.Panel.session.panelDefRules.referenceGroups[conceptKey][
            fieldKey
          ][rules].filter(function (rule) {
            return (
              rule.REF_TYPE === "CONCEPTS" &&
              rule.REF_CONCEPT !== rule.TARGET_CONCEPT
            );
          });
        if (foreignConceptsRules.length > 0) {
          PanelDefRules.processRules(foreignConceptsRules, $scope.Panel);
        }
      }
    };

    /**
     * Create a collection watcher for each array concept to calculate its items available
     */
    var createArrayConceptWatchers = function () {
      var conceptsToWatch = $scope.Panel.fullProgrammingConceptsWithDisplay;
      // Manually push this one, since it's a non-display concept, nested inside a Display concept.
      if (
        $scope.Panel.isXR550Family($scope.Panel.panel_model) &&
        ((+$scope.Panel.panel_version >= 183 &&
          +$scope.Panel.panel_version <= 599) ||
          +$scope.Panel.panel_version >= 683)
      ) {
        conceptsToWatch.push(
          $scope.Panel.PDS.panel_def.CONCEPTS.card_formats.DISPLAY
        );
      }

      angular.forEach(conceptsToWatch, function (concept) {
        if (
          angular.isDefined(concept) &&
          concept.hasOwnProperty("key") &&
          DoesNestedPropertyExist(
            $scope,
            "Panel." + concept.key + ".isArray"
          ) &&
          $scope.Panel[concept.key].isArray === true
        ) {
          $scope.$watchCollection("Panel." + concept.key, function () {
            $scope.Panel.updateItemsAvailable(concept.key);
          });
        }
      });
    };

    $scope.checkWirelessLimit = function () {
      PanelCapabilitiesService.getNumWirelessZones($scope.controlSystem.panels);
    };

    $scope.setCompWirelessHexSn = () => {
      $scope.comp_wireless_hex_sn = competitorWirelessUsesHex(
        $scope.Panel.device_informations
      );
    };

    $scope.addZone = function () {
      $scope.Panel.newItem("zone_informations")
        .then(
          function (zone) {
            $scope.setZoneTypeDefaults(zone);
          },
          function (error) {}
        )
        .catch(function (error) {});
    };

    var panel_family =
      UserService.controlSystem.panels[UserService.controlSystem.panel_index]
        .hardware_family;
    var panel_model =
      UserService.controlSystem.panels[UserService.controlSystem.panel_index]
        .hardware_model;
    var software_version =
      UserService.controlSystem.panels[UserService.controlSystem.panel_index]
        .software_version;

    var xtOutputPanels = ["XT50"]; // XT30 family panels that support output schedules
    // Conditionally add XTLP to the outputPanels list based on software version
    if (
      panel_model === "XTLP" &&
      ((171 <= software_version && software_version <= 599) ||
        software_version >= 671)
    ) {
      xtOutputPanels.push("XTLP");
    }

    // Setup temporary zone values for CellComSL only!
    // TODO: Use PanelDef to configure these values dynamically
    const cellComSlOutputValues = [
      { ord: 0, val: "000" },
      { ord: 1, val: "001" },
      { ord: 2, val: "002" },
      { ord: 3, val: "F01" },
      { ord: 4, val: "F02" },
      { ord: 5, val: "F03" },
      { ord: 6, val: "F04" },
      { ord: 7, val: "F05" },
      { ord: 8, val: "F06" },
      { ord: 9, val: "F07" },
      { ord: 10, val: "F08" },
      { ord: 11, val: "F09" },
      { ord: 12, val: "F10" },
      { ord: 13, val: "F11" },
      { ord: 14, val: "F12" },
      { ord: 15, val: "F13" },
      { ord: 16, val: "F14" },
      { ord: 17, val: "F15" },
      { ord: 18, val: "F16" },
      { ord: 19, val: "F17" },
      { ord: 20, val: "F18" },
      { ord: 21, val: "F19" },
      { ord: 22, val: "F20" },
    ];
    const cellComExOutputValues = [
      { ord: 0, val: "000" },
      { ord: 1, val: "001" },
    ];
    const miniCellComOutputValues = cellComSlOutputValues.splice(1, 2);

    $scope.outputValues =
      panel_model === "MiniCellCom"
        ? miniCellComOutputValues
        : panel_model === "CellComEX"
        ? cellComExOutputValues
        : cellComSlOutputValues;

    var hardwareModel, softwareVersion;

    var concepts = [];

    function getPanelConceptsForHardwareModel(hardwareModel) {
      if (["XR550", "XR350", "XR150"].includes(hardwareModel)) {
        return [
          "system_area_information",
          "user_codes",
          "profiles",
          "time_schedules",
          "holiday_dates",
          "favorites",
        ];
      } else if (["XT75"].includes(hardwareModel)) {
        return [
          "system_area_information",
          "user_codes",
          "profiles",
          "time_schedules",
          "holiday_dates",
          "favorites",
        ];
      } else if (hardwareModel === "CellComEX") {
        return ["user_codes"];
      } else if (hardwareModel === "TMS6") {
        return ["user_codes", "output_schedules", "schedules"];
      } else {
        return [
          "user_codes",
          "favorite_schedules",
          "output_schedules",
          "schedules",
          "favorites",
        ];
      }
    }

    switch (true) {
      /**
       * If the state is one of the FULL programming states, then we need to load every concept that is defined in the
       * PanelDefinition data.  That will be done automatically by the Panel model.
       */
      case /^.*.programming_full/.test($state.current.name):
        concepts = []; // need to have this case return nothing to prevent full programming from retrieving since GQL is taking over this duty.
        break;
      case /^.*.programming$/.test($state.current.name):
        concepts = getPanelConceptsForHardwareModel(panel_model); // need to have this case return nothing to prevent full programming from retrieving since GQL is taking over this duty.
        break;
      case /^.*.programming_print/.test($state.current.name):
        concepts = [];
        if (panel_model === "TMS6") {
          concepts = [
            "area_informations",
            "communication",
            "lockout_code",
            "network_options",
            "output_options",
            "remote_options",
            "system_options",
            "system_reports",
            "user_codes",
            "zone_informations",
          ];
        }
        break;
      /**
       * If we are not a FULL state, then we only need a few concepts, which we will manually define.
       */
      case /^.*.programming_com/.test($state.current.name):
        concepts = [
          "communication",
          "system_options",
          "network_options",
          "remote_options",
          "output_options",
          "zone_informations",
          "area_informations",
        ];
        if (panel_model === "iComLNC" || panel_model === "DualCom") {
          concepts.push("network_options");
          $scope.concept = {};
        }
        break;
      case /^.*.area_settings/.test($state.current.name):
        concepts = [
          "time_schedules",
          "area_informations",
          "favorites",
          "device_informations",
          "system_area_information",
          "system_options",
          "output_informations",
        ];
        break;
      case /^.*.schedules_xr/.test($state.current.name):
        concepts = [
          "time_schedules",
          "area_informations",
          "favorites",
          "device_informations",
          "system_area_information",
          "system_options",
          "output_informations",
          "capabilities",
        ];
        break;
      case /^.*.schedule_new_xr/.test($state.current.name):
        concepts = [
          "time_schedules",
          "area_informations",
          "favorites",
          "device_informations",
          "system_area_information",
          "system_options",
          "output_informations",
          "capabilities",
        ];
        break;
      case /^.*.schedule_edit_xr/.test($state.current.name):
        concepts = [
          "time_schedules",
          "area_informations",
          "favorites",
          "device_informations",
          "system_area_information",
          "system_options",
          "output_informations",
          "capabilities",
        ];
        break;
      case /^.*.schedules_list_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "system_options",
          "capabilities",
        ];
        if (
          panel_family === "XT30" &&
          xtOutputPanels.indexOf(panel_model) !== -1
        ) {
          concepts.push("output_schedules", "output_informations");
        }
        if (panel_model === "MiniCellCom") {
          concepts.splice(concepts.indexOf("schedules"), 1);
        }
        if (panel_model === "TMS6") {
          const excludedConcepts = ["favorite_schedules", "favorites"];
          concepts = concepts.filter(
            (concept) => !excludedConcepts.includes(concept)
          );
          concepts.push("output_schedules");
        }
        break;
      case /^.*.schedule_new_arming_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "system_options",
        ];
        if (
          panel_family === "XT30" &&
          xtOutputPanels.indexOf(panel_model) !== -1
        ) {
          concepts.push("output_schedules", "output_informations");
        }
        break;
      case /^.*.schedule_edit_arming_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "system_options",
        ];
        if (
          panel_family === "XT30" &&
          xtOutputPanels.indexOf(panel_model) !== -1
        ) {
          concepts.push("output_schedules", "output_informations");
        }
        break;
      case /^.*.schedule_new_favorite_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "system_options",
          "capabilities",
        ];
        if (
          panel_family === "XT30" &&
          xtOutputPanels.indexOf(panel_model) !== -1
        ) {
          concepts.push("output_schedules", "output_informations");
        }
        if (panel_model === "MiniCellCom") {
          concepts.splice(concepts.indexOf("schedules"), 1);
        }
        break;
      case /^.*.schedule_edit_favorite_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "system_options",
          "capabilities",
        ];
        if (
          panel_family === "XT30" &&
          xtOutputPanels.indexOf(panel_model) !== -1
        ) {
          concepts.push("output_schedules", "output_informations");
        }
        if (panel_model === "MiniCellCom") {
          concepts.splice(concepts.indexOf("schedules"), 1);
        }
        break;
      case /^.*.schedule_new_output_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "output_schedules",
          "system_options",
          "output_informations",
          "capabilities",
        ];
        break;
      case /^.*.schedule_edit_output_xt/.test($state.current.name):
        concepts = [
          "schedules",
          "area_informations",
          "favorite_schedules",
          "favorites",
          "output_schedules",
          "system_options",
          "output_informations",
          "capabilities",
        ];
        break;
      case /^.*.user_codes/.test($state.current.name):
        // Since profiles only exist on XR550 family panels, don't load that concept if we're on an XT30 family panel
        concepts = [
          "user_codes",
          "area_informations",
          "system_options",
          "capabilities",
        ];
        if (UserService.controlSystem.panels[0].hardware_family === "XR550") {
          concepts.push("profiles");
        }
        break;
      case /^.*.groups/.test($state.current.name):
      case /^.*.profiles/.test($state.current.name):
        concepts = [
          "profiles",
          "area_informations",
          "time_schedules",
          "system_options",
          "device_informations",
        ];
        break;
      case /^.*.system_tests/.test($state.current.name):
        concepts = ["zone_informations"];
        // Since zwave doesn't exist on MiniCellCom or TMS6 panels, don't load that concept if we're on one of those panels
        if (panel_model !== "MiniCellCom" && panel_model !== "TMS6") {
          concepts.push("zwave_setups");
        }
        // Since communications paths only exist on XR550 family panels, don't load that concept if we're on an XT30 family panel load communication instead
        if (["XR550", "XF6", "XT75"].includes(panel_family)) {
          concepts.push("communication_paths");
        }
        if (["XT30", "TMSentry"].includes(panel_family)) {
          concepts.push("communication");
        }
        break;
      default:
        concepts = [];
        break;
    }

    $scope.Panel = {};
    $scope.controlSystem = {};

    // Clear out the array that holds currently open panels
    $rootScope.openProgPanels = [];
    // Verify that at least one control_system exists in ControlSystemsService
    if (
      !ControlSystemsService.control_systems_list.length &&
      UserService.control_system_id !== "new"
    ) {
      ControlSystemsService.control_systems_list = [
        {
          customer_id: UserService.customer_id,
          control_system_id: UserService.control_system_id,
        },
      ];
    }

    /**
     * This function gets a control_system from the ControlSystemsService
     * @returns {Promise} promise to be resolved
     */
    var getControlSystem = function () {
      var deferred = $q.defer();
      ControlSystemsService.getControlSystem(
        UserService.control_system_id,
        UserService.customer_id
      )
        .then(
          function () {
            $scope.controlSystem = ControlSystemsService.currentControlSystem;

            // Set the local variables to the new control system values
            hardwareModel =
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .hardware_model;
            softwareVersion =
              $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                .software_version;
            $scope.userAccount =
              $scope.controlSystem.panels[
                $scope.controlSystem.panel_index
              ].account_number;
            $scope.userRemoteKey =
              $scope.controlSystem.panels[
                $scope.controlSystem.panel_index
              ].remote_key;

            if (!$state.is("app.panel_programming_full")) {
              // If this is NOT a Network panel, remove network_options concept from the list
              if (
                $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                  .comm_type !== "network"
              ) {
                if (
                  concepts.indexOf("network_options") >= 0 &&
                  panel_model !== "TMS6"
                ) {
                  concepts.splice(concepts.indexOf("network_options"), 1);
                  delete Panel["network_options"];
                }
              }

              // If this is an XTL panel and simple com programming, we need to add some additional concepts
              if (
                $scope.controlSystem.panels[
                  $scope.controlSystem.panel_index
                ].hardware_model.indexOf("XTL") >= 0 &&
                /^.*.programming_com/.test($state.current.name)
              ) {
                concepts.push("output_informations");
                concepts.push("area_informations");
                concepts.push("bell_options");
              }
            }
            deferred.resolve();
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error retrieving Control System",
              json: error,
            });
            deferred.reject();
          }
        )
        .catch(function (error) {
          console.error(error);
        });
      return deferred.promise;
    };

    // Filter for which full programming concepts will be shown in the accordion of full programming
    $scope.shownFullConcept = function (concept) {
      // This is all to say area_informations for 550 family is not shown (note: because it's buried within system_area_information).
      var shouldShow = !(
        ("XR550" ===
          $scope.controlSystem.panels[$scope.controlSystem.panel_index]
            .hardware_family &&
          "area_informations" === concept.key) ||
        ($scope.Panel.isXR550Family($scope.Panel.panel_model) &&
          ((+$scope.Panel.panel_version >= 183 &&
            +$scope.Panel.panel_version <= 599) ||
            +$scope.Panel.panel_version >= 683) &&
          concept.key === "card_formats")
      );
      return shouldShow;
    };

    $scope.saveXRCombinedDeviceInfo = function (forceSend) {
      let suppressToast = false;
      $scope.Panel.sendProgramming(
        ["device_informations", "card_formats"],
        suppressToast,
        forceSend
      )
        .then(
          function () {
            var successMessage = forceSend
              ? "successfully sent device programming to the system."
              : "successfully sent device programming changes to the system.";
            $rootScope.alerts.push({ type: "success", text: successMessage });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error sending device programming to the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.canShowFullProgrammingComponent = () => {
      return "refresh" in $scope.Panel;
    };

    /*
      concepts: Required array of concepts to refresh, send '[]' if you want all concepts associated with the route to refresh
    */
    $scope.refreshConceptsFromReact = (concepts) => {
      // Since the Panel model (Panel.js) uses the 'this' keyword, just injecting it doesn't work because React doesn't have that context.
      // For this case I decided to let Angular handle the relationship between the controller and the model.
      $scope.Panel.refresh(concepts, false);
    };

    $scope.retrieveXRCombinedDeviceInfo = function () {
      $scope.Panel.refresh(["device_informations", "card_formats"])
        .then(
          function () {},
          function (error) {
            console.error(
              "Failed to retrieve System Device Information or Card Formats. Error: " +
                error
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Function to save XR System Area Information and Area Informations. These two concepts need a custom function
     * since they are combined in full programming
     */
    $scope.saveXRAreaInformation = function (forceSend) {
      let suppressToast = false;
      $scope.Panel.sendProgramming(
        ["area_informations", "system_area_information"],
        suppressToast,
        forceSend
      )
        .then(
          function () {
            var successMessage = forceSend
              ? "successfully sent area programming to the system."
              : "successfully sent area programming changes to the system.";
            $rootScope.alerts.push({ type: "success", text: successMessage });
          },
          function (error) {
            $rootScope.alerts.push({
              type: "error",
              text: "Error sending area programming to the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Function to retrieve XR System Area Information and Area Informations. These two concepts need a custom
     * function since they are combined in full programming
     */
    $scope.retrieveXRAreaInformation = function () {
      $scope.Panel.refresh(["system_area_information", "area_informations"])
        .then(
          function () {},
          function (error) {
            console.error(
              "Failed to retrieve System Area Information or Area Information. Error: " +
                error
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.maxAreasForArmingMode = function () {
      return numberRangeToCSV(
        $scope.Panel.PDS.panel_def.CONCEPTS["area_informations"].number.DISPLAY
          .PLACEHOLDER
      ).length;
    };

    /**
     * Send a concept to the panel. ex: system_options, zone_informations
     * @param {string} conceptKey - Name of a panel concept. ex: zone_informations
     */
    $scope.sendConcept = function (conceptKey) {
      $scope.Panel.sendProgramming(conceptKey)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text:
                "Successfully sent " +
                PANEL_CONCEPTS[conceptKey].data_name +
                " programming changes to the system.",
            });
          },
          function (error) {
            console.error(
              "ProgrammingRouterCtrl->sendConcept() - error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text:
                "Error sending " +
                PANEL_CONCEPTS[conceptKey].data_name +
                " programming to the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Send a concept to the panel, even if there are no changes. ex: system_options, zone_informations
     * @param {string} conceptKey - Name of a panel concept. ex: zone_informations
     */
    $scope.forceSendConcept = function (conceptKey) {
      let suppressToast = false;
      let forceSend = true;
      $scope.Panel.sendProgramming(conceptKey, suppressToast, forceSend)
        .then(
          function () {
            $rootScope.alerts.push({
              type: "success",
              text:
                "Successfully sent " +
                PANEL_CONCEPTS[conceptKey].data_name +
                " programming to the system.",
            });
          },
          function (error) {
            console.error(
              "ProgrammingRouterCtrl->sendConcept() - error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text:
                "Error sending " +
                PANEL_CONCEPTS[conceptKey].data_name +
                " programming to the system.",
              json: error,
            });
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Send all programming to the panel. If successful, save the controlSystem data (CellCom only)
     * This allows us to keep remote_key in sync between programming and system information.
     */
    $scope.sendAllProgramming = function () {
      PanelProgrammingService.sendingAllProgramming = true;

      $scope.Panel.sendProgramming([], true, false)
        .then(
          function () {
            PanelProgrammingService.sendingAllProgramming = false;
            if (
              $scope.Panel.isCellCom(
                $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                  .hardware_model
              )
            ) {
              $scope.controlSystem.save();
            }
          },
          function (error) {
            PanelProgrammingService.sendingAllProgramming = false;
            console.error(
              "ProgrammingRouterCtrl->sendAllProgramming() - Error saving programming: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Send all programming to the panel with the foceSend flag set to true.  This will send even if nothing has been changed.
     * If successful, save the controlSystem data (CellCom only)
     * This allows us to keep remote_key in sync between programming and system information.
     */
    $scope.forceSendAllProgramming = function () {
      PanelProgrammingService.sendingAllProgramming = true;
      InitialConnectionService.bypassForSCAPICorrection = false;
      let suppressToast = true;
      let forceSend = true;
      $scope.Panel.sendProgramming(
        [],
        suppressToast,
        forceSend,
        undefined,
        !UserService.controlSystem.panels[0].online
      )
        .then(
          function () {
            PanelProgrammingService.sendingAllProgramming = false;
            if (
              $scope.Panel.isCellCom(
                $scope.controlSystem.panels[$scope.controlSystem.panel_index]
                  .hardware_model
              )
            ) {
              $scope.controlSystem.save();
            }
          },
          function (error) {
            PanelProgrammingService.sendingAllProgramming = false;
            console.error(
              "ProgrammingRouterCtrl->sendAllProgramming() - Error saving programming: " +
                angular.toJson(error)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Determine the number of zones that are available to be used by this panel
     */
    $scope.zonesRemaining = function () {
      if ($scope.Panel.zone_informations) {
        var zonesUsed = $scope.Panel.zone_informations.length;
        var zonesTotal = $scope.Panel.getConfigValues("valid_zones").length;
        return parseInt(zonesTotal) - parseInt(zonesUsed);
      }
      return false;
    };
    $rootScope.isRestoringPanel = PanelProgArchiveService.restoringPanel;

    /**
     * Creates and opens a modal for restoring a panel from a backup file.
     */
    $scope.openRestorePanelFromBackupModal = function () {
      if (!$rootScope.isRestoringPanel) return;
      var restorePanelFromBackupModal = $modal.open({
        templateUrl: "restore-panel-from-backup-modal.html",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });
    };
    /**
     * Creates and opens a modal to display panel connect errors
     */
    $scope.openErrorModal = function () {
      if ($scope.isDead) return;
      var errorModal = $modal.open({
        templateUrl: "errorModalContent.html",
        controller: "PanelErrorModalCtrl",
        windowClass: "dialog-header-error",
        size: "md",
        backdrop: "static",
        scope: $scope,
      });
      errorModal.result
        .then(
          function (reason) {
            if (ReplacePanelService.replacingPanel()) {
              init();
            } else {
              $state.forceReload();
            }
          },
          function () {}
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    /**
     * Set the defaults for the 4 act_msg fields, based on which value is set for the zone.tipe field
     * TODO: Use the data in the PanelDefinitionService to dynamically form this logic
     * @param {Object} zone the zone
     */
    $scope.setZoneTypeDefaults = function (zone) {
      switch (zone.tipe) {
        case "--":
        case "NT":
          zone.do_act_msg = "-";
          zone.ds_act_msg = "-";
          zone.ao_act_msg = "A";
          zone.as_act_msg = "A";
          break;
        case "DY":
          zone.chime_snd = "0";
          zone.do_act_msg = "T";
          zone.ds_act_msg = "T";
          zone.ao_act_msg = "A";
          zone.as_act_msg = "A";
          break;
        case "EX":
          zone.do_act_msg = "-";
          zone.ds_act_msg = "-";
          zone.ao_act_msg = "A";
          zone.as_act_msg = "A";
          break;
        case "FI":
        case "PN":
        case "EM":
        case "SV":
          zone.do_act_msg = "-";
          zone.ds_act_msg = "-";
          zone.ao_act_msg = "T";
          zone.as_act_msg = "A";
          break;
        case "FV":
          zone.chime_snd = "0";
          zone.do_act_msg = "-";
          zone.ds_act_msg = "-";
          zone.ao_act_msg = "T";
          zone.as_act_msg = "A";
          break;
        case "A1":
        case "A2":
          zone.chime_snd = "0";
          zone.do_act_msg = "T";
          zone.ds_act_msg = "A";
          zone.ao_act_msg = "T";
          zone.as_act_msg = "A";
          break;
        case "CO":
          zone.do_act_msg = "-";
          zone.ds_act_msg = "-";
          zone.ao_act_msg = "T";
          zone.as_act_msg = "A";
          break;
        case "IN":
          zone.do_act_msg = "N";
          zone.ds_act_msg = "N";
          zone.ao_act_msg = "A";
          zone.as_act_msg = "A";
          break;
        default:
          break;
      }
    };

    $scope.round5 = function (x) {
      return Math.ceil(x / 5) * 5;
    };

    $scope.orderConceptFields = function (arr) {
      return $filter("min")($filter("map")(arr, "DISPLAY.ORDER"));
    };

    $scope.omitConceptDisplay = function (field) {
      return field.$key === "DISPLAY";
    };

    /** Hide a print programming field */
    $scope.omitPrintProgField = function (field) {
      const isXf = $scope.Panel.isXf($scope.Panel.panel_model);

      if (isXf) {
        const hideRemoteKey = field.$key === "crypt_key";
        return hideRemoteKey;
      }
    };

    //This will check to see if a panel def field is cid format or host communication format
    $scope.isCidFormatAndHostCommFormat = function (field) {
      return field.$key === "host_comm_format" || field.$key === "cid_format";
    };

    //This will parse the actual capture format based on cid format and host comm format
    //Capture format will be a combination of cid and host so it will be one of these options
    //For the capture format to be
    // DMP: CID Format would need to be 0 and host comm format would need to be 0
    // CID: CID = 1, HOST = 0
    // 4-2: CID = 0, HOST = 1
    // SIA: CID = 0, HOST = 2
    //And to return any of the keys above we would need to map it to the panel def object that we
    //manually insert above
    // DMP: "0",
    // CID: "1",
    // "4-2": "2",
    // SIA: "3",
    const setCaptureFormat = (cid, host) => {
      switch (cid) {
        case "0":
          switch (host) {
            case "0":
              $scope.captureFormat = "0";
              break;
            case "1":
              $scope.captureFormat = "2";
              break;
            case "2":
              $scope.captureFormat = "3";
              break;
            default:
              $scope.captureFormat = "0";
              break;
          }
          break;
        case "1":
          $scope.captureFormat = "1";
          break;
        default:
          $scope.captureFormat = "0";
          break;
      }
    };
    $scope.captureFormat = "";
    $scope.$watch("Panel.system_options", function () {
      if (
        angular.isDefined($scope.Panel.system_options?.cid_format) &&
        angular.isDefined($scope.Panel.system_options.host_comm_format)
      ) {
        setCaptureFormat(
          $scope.Panel.system_options?.cid_format,
          $scope.Panel.system_options.host_comm_format
        );
      }
    });

    /**
     * Show only non-zwave zones.
     * If the number was accidentally modified on a regular zone, don't include that in the zwave zone list.
     */
    $scope.omitLNCZwaveZones = function (zone) {
      return (
        +zone.number >= 5 &&
        +zone.number <= 19 &&
        $scope.controlSystem.panels[$scope.controlSystem.panel_index]
          .hardware_model === "iComLNC" &&
        !zone.isNew
      );
    };

    $scope.commPathHasEncryption = (path) => path.encryption === "Y";

    $scope.showEncryptionPassphrase = () =>
      $scope.Panel.communication_paths.some((path) =>
        $scope.commPathHasEncryption(path)
      );

    /**
     * Show only zwave zones that were added through the panel.
     * If a zone isNew, it was added through the DA interface.
     */
    $scope.omitLNCNormalZones = function (zone) {
      return +zone.number < 5 || +zone.number > 19 || zone.isNew;
    };

    $scope.omitIsBusy = function (field) {
      return field.$key === "isBusy";
    };

    $scope.omitPrintConcept = function (concept) {
      // Always exclude
      if (["system_area_information"].includes(concept.key)) {
        return true;
      }
      // Don't exclude any backup concepts
      if (PanelProgArchiveService.cache.backup.data) {
        return false;
      }
      // Don't exclude full concepts
      return concept.FULL !== "Y";
    };

    $scope.openTab = function (button_number) {
      alert("The " + button_number + " button has been clicked.");
      $scope.selectedTab = button_number;
    };

    $scope.fobTabs = {
      Top: { active: true },
      Bottom: { active: false },
      Left: { active: false },
      Right: { active: false },
    };

    $scope.shouldDisplayKeyfobButtonTab = function (group, buttons) {
      switch (buttons) {
        case "1":
          return ["Top"].indexOf(group.$key) > -1;
        case "2":
          return ["Top", "Bottom"].indexOf(group.$key) > -1;
        case "4":
          return ["Top", "Bottom", "Right", "Left"].indexOf(group.$key) > -1;
        default:
          return false;
      }
    };

    $scope.shouldDisplayZoneKeyfobButtonTab = function (group, twoButtonPanic) {
      // Zone Keyfobs depend on the two_botton_panic field for their button tabs
      switch (twoButtonPanic) {
        case "N":
          return ["Top"].indexOf(group.$key) > -1;
        case "Y":
          return ["Top", "Bottom"].indexOf(group.$key) > -1;
        default:
          return false;
      }
    };

    $scope.omitZoneKeyfobFields = function (field) {
      //These are the only fields we need to show for Zone Keyfobs
      const allowedFields = new Set([
        "number",
        "kf_serial",
        "sup_time",
        "buttons",
        "action_b1",
        "press_tm1",
        "action_b4",
        "press_tm4",
      ]);
      return !allowedFields.has(field.$key);
    };

    $scope.convertZoneDataToKeyfobData = function (field, item) {
      //Takes the keyfob field and returns the correct zone data for Zone Keyfobs
      switch (field) {
        case "number":
          return item.number;
        case "kf_serial":
          return item.serial_no;
        case "sup_time":
          return "0";
        case "buttons":
          return item.two_button_panic === "Y" ? "2" : "1";
        case "action_b1":
          return item.two_button_panic === "Y" ? "06" : "05";
        case "press_tm1":
          return "1";
        case "action_b4":
          return item.two_button_panic === "Y" ? "06" : "05";
        case "press_tm4":
          return "1";
        default:
          return "";
      }
    };

    $scope.getUserCodes = function (item) {
      // Trim the left zero off all keyfob user codes
      //item.user_num = item.user_num.substr(1);
      $scope.Panel.fetch("user_codes")
        .then(
          function (data) {
            // Loop through the user_codes and add a left zero...painful, but VK only returns 4 digits.
            angular.forEach($scope.Panel.user_codes, function (user_code) {
              user_code.number = "0" + user_code.number;
            });
          },
          function (error) {}
        )
        .catch(function (error) {
          console.error(error);
        });
    };

    $scope.$on("$destroy", function () {
      $scope.isDead = true;
    });

    /**
     * Sets the default contact number field, based on whether the serial number is type 01 or type 08, and based
     * on the existing contacts on zones sharing the same serial number
     * @param serialNumber
     * @param thisItem
     * @returns {boolean}
     */
    $scope.zoneSerialNumChange = function (serialNumber, thisItem) {
      var zoneType = serialNumber.substr(0, 2);
      if (
        serialNumber.length < 8 ||
        (zoneType !== "01" && zoneType !== "07" && zoneType !== "08")
      )
        return false;
      // Find any zones that have the same serial number as this one, but aren't this one :)
      var matchingZones = $filter("filter")(
        $scope.Panel.zone_informations,
        function (zone) {
          return (
            zone.serial_no === serialNumber && zone.number !== thisItem.number
          );
        }
      );

      // If there are no matching zones, we don't need to do anything
      if (matchingZones.length === 0 && zoneType !== "07") return false;

      // Get the list of contacts that already have been set for any matching zones
      var contactsList = [];
      var matchingZoneNumbers = [];
      angular.forEach(matchingZones, function (zone) {
        contactsList.push(zone.contact_no);
        matchingZoneNumbers.push(zone.number);
      });

      // If the serial is an 01
      if (zoneType === "01") {
        if (matchingZones.length >= 2) {
          thisItem.serial_no = "";
          $rootScope.alerts.push({
            type: "error",
            text: "Zones have the same serial number.  Contacts must be different.",
          });
          return false;
        }
        // Check to make sure the previous zone is adjacent to this zone
        if (!isNumberWithinRange(thisItem.number, matchingZoneNumbers, 2)) {
          thisItem.serial_no = "";
          $rootScope.alerts.push({
            type: "error",
            text: "Zones for type 01 devices with matching serial numbers must be sequential.",
          });
          return false;
        }
        // Set the contact to the next available slot
        thisItem.contact_no = nextManUpMask(contactsList, ["0", "1"]);
      } else if (zoneType === "07" && $scope.panelSupports1168()) {
        thisItem.contact_no = $scope.Panel.get07ZoneContactNum(
          thisItem,
          $scope.Panel.PDS.panel_def.CONCEPTS.zone_informations.number.DISPLAY
            .PLACEHOLDER
        );
      } else if (zoneType === "") {
        if (matchingZones.length >= 4) {
          thisItem.serial_no = "";
          $rootScope.alerts.push({
            type: "error",
            text: "Zones have the same serial number.  Contacts must be different.",
          });
          return false;
        }
        // Check to make sure the previous zone is adjacent to (or within range of) this zone
        if (!isNumberWithinRange(thisItem.number, matchingZoneNumbers, 4)) {
          thisItem.serial_no = "";
          $rootScope.alerts.push({
            type: "error",
            text: "Zones for type 08 devices with matching serial numbers must be within 4 sequential zone numbers.",
          });
          return false;
        }
        // Set the contact to the next available slot
        thisItem.contact_no = nextManUpMask(contactsList, ["0", "1", "2", "3"]);
      }
    };

    /**
     * Gets an existing panel test or creates a new one with the given testType
     * @param panelId {string} The panel ID that the test is running on
     * @param testType {string} The type of test we're running (PANEL_TEST_TYPE)
     * @param autoUpdate {boolean} Should the panel test fetch status updates automatically
     * @returns {Object} PanelTest the existing or newly created panel test
     */
    $scope.getPanelTest = function (panelId, testType, autoUpdate) {
      var panelTest = $filter("filter")(PanelTestService.tests, {
        panelId: panelId,
        testType: testType,
      })[0];

      if (panelTest) {
        // We found an existing comTest, check for updates
        if (autoUpdate) {
          panelTest.startUpdateTimer();
        }
      } else {
        // Create a new test object for this panel in the panelTestService
        panelTest = new PanelTest(
          UserService.dealer_id,
          panelId,
          testType,
          autoUpdate
        );
        PanelTestService.tests.push(panelTest);
      }

      return panelTest;
    };

    // All/Perimeter and Home/Sleep/Away systems have predefined areas: PERIMETER, INTERIOR and BEDROOMS (HSA only).
    // Default areas are generated in 2 circumstances:
    // 1. When both system_options and area_informations are loaded
    // 2. When the user changes the arm mode to All/Perimeter or Home/Sleep/Away
    var armModeWatchData = {
      armModeLoaded: false,
      areasLoaded: false,
      firstRunChecked: false,
    };
    // Watch system_options.arm_mode to see when it's been intentionally changed
    $scope.$watch("Panel.system_options.arm_mode", function (newVal, oldVal) {
      // When both values are defined, the user initiated the change (assumption)
      if (angular.isDefined(newVal) && angular.isDefined(oldVal)) {
        generateDefaultAreas(true);
      }
    });

    $scope.$watch("Panel.device_informations", function (newVal, oldVal) {
      if (angular.isDefined(newVal) && angular.isDefined(oldVal)) {
        $scope.has1100T = panelHas1100T(newVal);
        $scope.comp_wireless_hex_sn = competitorWirelessUsesHex(newVal);
      }
    });
    /**
     * Generate default areas for All/Perimeter and Home/Sleep/Away systems
     * @param {{boolean}} userChangedArmMode - indicates the user changed the system options value; synonymous with a
     * force change
     */
    function generateDefaultAreas(userChangedArmMode) {
      // Only when both system_options and area_informations have been loaded
      if (armModeWatchData.armModeLoaded && armModeWatchData.areasLoaded) {
        var firstTimeProcess = !armModeWatchData.firstRunChecked;
        armModeWatchData.firstRunChecked = true;
        var armModeHasPredefinedAreas =
          ["A", "H"].indexOf($scope.Panel.system_options.arm_mode) > -1 &&
          panel_model !== "DualCom" &&
          panel_model !== "CellComSL" &&
          panel_model !== "iComSL" &&
          panel_model !== "iComLNC";
        if (
          armModeHasPredefinedAreas &&
          (firstTimeProcess || userChangedArmMode)
        ) {
          // Collect all the panel def rules that are processed for areas
          var areaDefaultRules = [];
          var areaRuleTargetFields = Object.keys(
            $scope.Panel.session.panelDefRules.targetGroups.area_informations
          );
          angular.forEach(areaRuleTargetFields, function (field) {
            areaDefaultRules = areaDefaultRules.concat(
              $scope.Panel.session.panelDefRules.targetGroups.area_informations[
                field
              ].defaultRules
            );
          });
          // Set the appropriate number of areas based on arm mode
          var numAreas =
            $scope.Panel.system_options.arm_mode.toString() === "A" ? 2 : 3;
          // If areas have been saved in the past, they will come in on the fetch from SCAPI so start by saying that
          // areas are valid if there are the same number of areas as there should be
          var areasValid = numAreas === $scope.Panel.area_informations.length;
          // If the user initiated the change, we know we need to rebuild areas
          if (userChangedArmMode) {
            areasValid = false;
          }
          if (areasValid) {
            // If there are the right number of areas, make sure they're the right names and numbers
            var areaNames =
              $scope.Panel.session.panelDefRules.targetGroups.area_informations
                .name.defaultRules[0].REFERENCE_VALUES_MAP;
            for (var i = 0; i < numAreas; i++) {
              if (
                $scope.Panel.area_informations[i].name !==
                areaNames[$scope.Panel.area_informations[i].number]
              ) {
                areasValid = false;
                break;
              }
            }
          }
          if (!areasValid) {
            // Clear area_informations and set the properties that we attach to them
            $scope.Panel.area_informations = [];
            $scope.Panel.area_informations.isBusy = true;
            $scope.Panel.area_informations.isArray = true;
            // Create the appropriate number of areas
            var promises = [];
            for (var j = 0; j < numAreas; j++) {
              promises.push($scope.Panel.newItem("area_informations"));
            }
            $q.all(promises)
              .then(
                function () {
                  // Apply default rules to each area so that the rules are run for the ones that aren't open on the screen
                  angular.forEach(
                    $scope.Panel.area_informations,
                    function (area) {
                      PanelDefRules.processRules(
                        areaDefaultRules,
                        $scope.Panel,
                        area.number
                      );
                    }
                  );
                  $scope.Panel.area_informations.isBusy = false;
                },
                function (error) {
                  console.error(
                    "Error creating areas for arm mode change: " +
                      angular.toJson(error)
                  );
                  $scope.Panel.area_informations.isBusy = false;
                }
              )
              .catch(function (error) {
                console.error(error);
              });
          }
        }
      }
    }

    /**
     * Initialize the scoped panel object if it hasn't already been done
     */
    function ensurePanelInitialized() {
      var deferred = $q.defer();
      // note: When replacing a panel, the programming data will be cached so only initialize the panel object if it hasn't been done.
      if ($scope.Panel.hasOwnProperty("panel_id") || concepts[0] === "none") {
        deferred.resolve();
      } else {
        PanelDefinitionService.generate(hardwareModel, softwareVersion)
          .then(
            function (PDS) {
              if (
                PDS.hardwareProperties.Series === "CellCom" &&
                panelVersionGTOE(202, PDS.firmwareVersion)
              ) {
                //Because CID format and Host Format are being combined into a single field
                //and won't be done on the backend, we are manually creating that field here.
                PDS.panel_def.CONCEPTS.system_options["capture_format"] = {
                  VALUES: {
                    DMP: "0",
                    CID: "1",
                    "4-2": "2",
                    SIA: "3",
                  },
                  DISPLAY: {
                    Data_Type: "List",
                    NAME: "Capture Format",
                    ORDER: 29,
                    TOOLTIP:
                      "Allows selection of DMP or Capture format in system options of Comm Series Panels.",
                  },
                };
              }
              $scope.Panel = new Panel(
                UserService.panel_id,
                hardwareModel,
                softwareVersion,
                concepts,
                PDS
              );

              trimUndefinedConcepts();
              $scope.checkWirelessLimit();
              deferred.resolve();
            },
            function (error) {
              console.error("error generating PDS: " + angular.toJson(error));
              $rootScope.alerts.push({
                type: "error",
                text: "error getting panel definition",
              });
              deferred.reject();
            }
          )
          .catch(function (error) {
            console.error(
              "caught error generating PDS: " + angular.toJson(error)
            );
            deferred.reject();
          });
      }
      return deferred.promise;
    }

    /**
     * Remove concepts not defined in panelDef from the list of concepts so that we don't attempt to retrieve
     * concepts that the panel doesn't support
     */
    function trimUndefinedConcepts() {
      if (
        DoesNestedPropertyExist($scope, "Panel.panel_model") &&
        $scope.Panel.isPanelFullyDefined($scope.Panel.panel_model)
      ) {
        var oConcepts = angular.copy(concepts);
        angular.forEach(oConcepts, function (concept) {
          var displayConcept = $scope.Panel.conceptsWithDisplay.find(function (
            c
          ) {
            return c.key === concept;
          });
          if (!displayConcept && concept !== "capabilities") {
            console.warn(
              "Removing " +
                concept +
                " from the list of concepts because it's not defned in PanelDef"
            );
            concepts.splice(concepts.indexOf(concept), 1);
          }
        });
      } else {
        // MiniCellCom is not defined in PanelDef. If it's ever added, we can remove the check that get's us here
        console.warn("Skipping ProgrammingRouterCtrl->trimUndefinedConcepts()");
      }
    }

    /**
     * Restore panel programming from the backup loaded in the PanelProgArchiveService
     */
    function restoreFromBackup() {
      if (PanelProgArchiveService.restoringPanel) {
        $scope.isRestoringFromBackup = true;
        $scope.Panel.allConceptsBusy = true;
        $scope.Panel.sendProgramming()
          .then(
            function () {
              $rootScope.alerts.push({
                type: "success",
                text: "successfully restored panel",
              });
              $scope.restoreIsSuccessful = true;
              $scope.isRestoringFromBackup = false;
              $scope.Panel.allConceptsBusy = false;
            },
            function (error) {
              console.error("error restoring panel: " + angular.toJson(error));
              $rootScope.alerts.push({
                type: "error",
                text: "error restoring panel",
              });
              $scope.restoreIsSuccessful = false;
              $scope.isRestoringFromBackup = false;
              $scope.Panel.allConceptsBusy = false;
            }
          )
          .finally(function () {
            ReplacePanelService.init();
            PanelProgArchiveService.cache.backup.clear(false);
          });
      }
    }
    /**
     * Push or pull information to or from panel being replaced via the replace panel modal
     */
    var processReplacementPanelData = function () {
      if (ReplacePanelService.replacingPanel()) {
        ensurePanelInitialized().then(function () {
          $scope.Panel.allConceptsBusy = true;
          var pushingData =
            ReplacePanelService.replacementPanelData.action.toString() ===
            "push";
          var replacePanelLogMessage = pushingData
            ? "Fetched all programming. Sending"
            : "Refreshing";
          replacePanelLogMessage += " all programming.";
          var replacePanelAction = pushingData
            ? $scope.Panel.sendProgramming()
            : $scope.Panel.refresh();
          replacePanelAction
            .then(
              function () {
                $scope.showSpinner = false;
                if (pushingData) {
                  var replacePanelSuccessText =
                    "All programming successfully sent to the new system.";
                  $rootScope.alerts.push({
                    type: "success",
                    text: replacePanelSuccessText,
                  });
                }
                if ($scope.Panel.isXR550Family($scope.Panel.panel_model)) {
                  // Feature keys specifically needs to come from the NEW panel so we do a GET once all other logic has completed
                  $scope.Panel.get("feature_keys");
                }
                UserService.conceptsWithDisplay =
                  $scope.Panel.conceptsWithDisplay;
                $scope.$broadcast("all_concepts_retrieved");
              },
              function (error) {
                $scope.showSpinner = false;
                $scope.Panel.allConceptsBusy = false;
                var sendErrorText = "Error ";
                sendErrorText += pushingData
                  ? "sending programming to"
                  : "retrieving programming from";
                sendErrorText += " new panel";
                console.error(
                  sendErrorText + " Error: " + angular.toJson(error)
                );
                if (pushingData) {
                  $scope.openErrorModal();
                } else {
                  $rootScope.alerts.push({
                    type: "error",
                    text: "Error retrieving programming from the new system",
                    json: error,
                  });
                }
              }
            )
            .catch(function (error) {
              console.error(error);
            })
            .finally(function () {
              ReplacePanelService.init();
              PanelProgArchiveService.cache.backup.clear(false);
            });
        });
      }
    };

    /**
     * Save control system and perform initial connection for replacing a panel
     */
    function performInitialConnectForReplacementPanel() {
      if (ReplacePanelService.replacingPanel()) {
        // Grab original serial number for reverting in case initial connection is not established
        var originalSerialNumber =
          ControlSystemsService.currentControlSystem.panels[
            ControlSystemsService.currentControlSystem.panel_index
          ].serial_number;
        ControlSystemsService.currentControlSystem.panels[
          ControlSystemsService.currentControlSystem.panel_index
        ].serial_number =
          ReplacePanelService.replacementPanelData.newSerialNumber;
        ControlSystemsService.currentControlSystem
          .replacePanelSave()
          .then(
            function () {
              ClientEventsService.initialConnection
                .getEvents(
                  ControlSystemsService.currentControlSystem.panels[
                    ControlSystemsService.currentControlSystem.panel_index
                  ].id
                )
                .then(
                  function () {
                    InitialConnectionService.ensureConnectionReady(true)
                      .then(
                        function () {
                          if (InitialConnectionService.didJobSucceed()) {
                            ReplacePanelService.replacementPanelData.initialConnectionCompleted = true;
                            processReplacementPanelData();
                          }
                          $scope.showSpinner = false;
                        },
                        function (error) {
                          console.error(
                            "ProgrammingRouterCtrl->performInitialConnectForReplacementPanel() - Error: " +
                              angular.toJson(error)
                          );
                          $scope.showSpinner = false;
                          var icStatus = InitialConnectionService.getJobData();
                          if (
                            ReplacePanelService.replacementPanelData
                              .cancelled ||
                            (icStatus &&
                              icStatus.JobMessage &&
                              icStatus.JobMessage === "Job cancelled")
                          ) {
                            // Restore serial number and return to
                            ControlSystemsService.currentControlSystem.panels[
                              ControlSystemsService.currentControlSystem.panel_index
                            ].serial_number = originalSerialNumber;
                            ControlSystemsService.currentControlSystem
                              .save()
                              .then(
                                function () {
                                  $state.go("app.control_system", {
                                    customer_id: UserService.customer_id,
                                    control_system_id:
                                      UserService.control_system_id,
                                  });
                                },
                                function (error) {
                                  console.error(
                                    "Error saving control system: " + error
                                  );
                                }
                              )
                              .catch(function (error) {
                                console.error(error);
                              });
                          } else {
                            $scope.openErrorModal();
                          }
                        }
                      )
                      .catch(function (error) {
                        console.error(error);
                      });
                  },
                  function (error) {
                    console.error(
                      "Error getting initial connection status for replacement panel: " +
                        error
                    );
                    $scope.showSpinner = false;
                  }
                )
                .catch(function (error) {
                  console.error(error);
                });
            },
            function (error) {
              console.error("Error saving control system: " + error);
              $scope.showSpinner = false;
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    function lncWiredZonesAvailable() {
      var wiredZoneCount = lncWiredZoneAvailableCount();
      // If the length is less than 4, there is still at least 1 zone available
      return wiredZoneCount > 0;
    }

    function lncWiredZoneAvailableCount() {
      // Get any zones that are 1-4
      var wiredZones = $filter("filter")(
        $scope.Panel.zone_informations,
        function (zone) {
          return Number(zone.number) < 5;
        }
      );
      return 4 - wiredZones.length;
    }

    /**
     * Initialize programming router controller.
     * note: Run on load. Also called when panel replacement fails and is retried so that cached data is allowed to persist.
     */
    function init() {
      // Only AFTER getting a control system, get the data for the panel
      getControlSystem()
        .then(function () {
          $scope.openRestorePanelFromBackupModal();
          // If there is a job in progress, let it finish
          InitialConnectionService.checkForRunningJob($scope.controlSystem)
            .then(function () {
              $scope.showSpinner = true;
              if (ReplacePanelService.replacingPanel()) {
                // If something went wrong during the transfer and retry was hit, the data will still be available
                if (
                  ReplacePanelService.replacementPanelData
                    .initialConnectionCompleted
                ) {
                  processReplacementPanelData();
                } else {
                  if (
                    ReplacePanelService.replacementPanelData.action.toString() ===
                    "push"
                  ) {
                    ensurePanelInitialized().then(function () {
                      // 1) If you'll be pushing data to a replacement panel, retrieve data before the initial connection since initial connection can change properties in various programming concepts
                      // 2) Cache concepts before changing the serial number on the control system because SCAPI may try to pull some information from the panel
                      let dataPromise = PanelProgArchiveService.cache.backup
                        .data
                        ? $scope.Panel.loadProgBackup()
                        : $scope.Panel.fetchAllConcepts();
                      dataPromise
                        .then(
                          function () {
                            performInitialConnectForReplacementPanel();
                          },
                          function (error) {
                            console.error(
                              "Unable to fetch concepts for push to replacement panel: " +
                                angular.toJson(error)
                            );
                            $scope.showSpinner = false;
                          }
                        )
                        .catch(function (error) {
                          console.error(error);
                        });
                    });
                    // Replacing panel and pulling information
                  } else {
                    performInitialConnectForReplacementPanel();
                  }
                }
              } else {
                // Not replacing panel, use VK cached data
                ensurePanelInitialized().then(function () {
                  // The scoped conceptPromise is picked up by daughter controllers
                  $scope.conceptPromise = PanelProgArchiveService.cache.backup
                    .data
                    ? $scope.Panel.loadProgBackup()
                    : $scope.Panel.fetchAllConcepts();
                  $scope.conceptPromise
                    .then(
                      function () {
                        if (PanelProgArchiveService.restoringPanel) {
                          restoreFromBackup();
                        } else {
                          UserService.conceptsWithDisplay =
                            $scope.Panel.conceptsWithDisplay;
                          // UserService.buildDropDownConcepts();
                          $scope.has1100T = panelHas1100T(
                            $scope.Panel.device_informations
                          );
                          $scope.supports1100T = supports1100T();
                          // If the wireless_translator_1100t_frequency is not Hex ('2' or '3'),
                          // we need to pad with leading zeros to equal 8 digits
                          $scope.comp_wireless_hex_sn =
                            competitorWirelessUsesHex(
                              $scope.Panel.device_informations
                            );
                          $scope.$broadcast("all_concepts_retrieved");
                          $scope.showSpinner = false;
                        }
                      },
                      function (error) {
                        if (error !== "STALE_STATE_CALL") {
                          $scope.unableToLoadPanel = true;
                          if (OnlinePanelService.isOnline()) {
                            $scope.openErrorModal();
                          }
                          $scope.showSpinner = false;
                        }
                      }
                    )
                    .catch(function (error) {
                      console.error(error);
                    });
                });
              }
            })
            .catch(function (error) {
              console.error(error);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    const showAutoProgTemplateError = () => {
      $rootScope.alerts.push({
        type: "error",
        text: AUTO_PROGRAM_TEMPLATE_FAILURE_MESSAGE,
      });
    };

    const stopPolling = () => {
      $scope.applyingTemplate = false;
      init();
    };

    const getTemplatingJobForThisPanel = async () => {
      const jobs = await JobSchedulerService.getJobsListByPanel(
        [PRE_PROGRAM_TEMPLATE_NAME],
        UserService.controlSystem.panels[0].id
      );

      if (!jobs || !jobs.length) {
        init();
      } else {
        return jobs[0].JobStatus;
      }
    };

    const checkStatusOfPendingJobGroup = async (pollCount = 0) => {
      if ($scope.isDead) {
        //we want to stop polling once the user leaves the page
        return;
      }

      try {
        const JobStatus = await getTemplatingJobForThisPanel();

        if (JobSchedulerService.isCompleteStatus(JobStatus)) {
          stopPolling();
        } else if (JobSchedulerService.isFailedStatus(JobStatus)) {
          showAutoProgTemplateError();
          stopPolling();
        } else {
          if (pollCount > 120) {
            showAutoProgTemplateError();
            stopPolling();
          } else {
            await sleep(1000);
            checkStatusOfPendingJobGroup(pollCount + 1);
          }
        }
      } catch {
        showAutoProgTemplateError();
        stopPolling();
      }
    };

    const prepareToInit = async () => {
      // If the panel is offline and we're on the full programming page we need to make sure that
      // we're not waiting on a template to be applied
      if (
        !UserService.controlSystem.panels[0].online &&
        /^.*.programming_full/.test($state.current.name)
      ) {
        try {
          const JobStatus = await getTemplatingJobForThisPanel();

          if (JobSchedulerService.isRunningStatus(JobStatus)) {
            $scope.applyingTemplate = true;
            checkStatusOfPendingJobGroup(0);
          } else {
            init();
          }
        } catch {
          showAutoProgTemplateError();
          stopPolling();
        }
      } else {
        init();
      }
    };
    prepareToInit();
  },
]);

/**
 * A controller specifically for the "unable to connect to panel" error
 */
App.controller(
  "PanelErrorModalCtrl",
  function ($scope, $modalInstance, $state, UserService) {
    $scope.reload = function () {
      $modalInstance.close("reload");
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel");
      $state.go("app.control_system", {
        customer_id: UserService.customer_id,
        control_system_id: UserService.control_system_id,
      });
    };
  }
);
