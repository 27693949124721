import graphql from "babel-plugin-relay/macro";
import DmpIcon from "common/components/DmpIcon";
import ArmingWidget from "common/components/web/ArmingWidget";
import {
  CycleIcon,
  PanelCommunicationIcon,
  ResetIcon,
  TimeClockIcon,
} from "common/components/web/Icons";
import StatusWidget from "common/components/web/StatusWidget";
import ZoneStatuses from "common/components/web/ZoneStatusWidget/ZoneStatuses";
import useBoundingClientRect from "common/hooks/useBoundingClientRect";
import { Flex } from "components/DaStyledElements";
import GenericSuspenseFallback from "components/GenericSuspenseFallback";
import LoadingSpinner from "components/LoadingSpinner";
import DefaultMoreButton from "components/MoreButton";
import RedirectTo404 from "components/SiteForm/RedirectTo404";
import { StatusBadge } from "components/StatusBadge";
import { AlertsContextProvider, useShowAlert } from "contexts/AlertsContext";
import * as React from "react";
import {
  RelayEnvironmentProvider,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from "react-relay/hooks";
import { idAsString, toControlSystemId } from "securecom-graphql/client";
import styled, { ThemeProvider } from "styled-components";
import DaColors, { defaultTheme } from "../../app/common/DaColors";
import LanguageProvider from "../LanguageProvider";
import DiagnosticLoadModal from "./DiagnosticLoadModal";
import DiagnosticModal from "./DiagnosticModal";
import DoorList from "./DoorList";
import ForgiveUserModal from "./ForgiveUserModal";
import SystemTestButton from "./SystemTest";
import TimeSetModal from "./TimeSetModal";
import { SystemStatusDoorListRefreshMutation } from "./__generated__/SystemStatusDoorListRefreshMutation.graphql";
import { SystemStatusLockdownMutation } from "./__generated__/SystemStatusLockdownMutation.graphql";
import { SystemStatusLxBusRefreshMutation } from "./__generated__/SystemStatusLxBusRefreshMutation.graphql";
import { SystemStatusQuery } from "./__generated__/SystemStatusQuery.graphql";
import { SystemStatusRefreshAreaInformationsAndArmedStatusMutation } from "./__generated__/SystemStatusRefreshAreaInformationsAndArmedStatusMutation.graphql";
import { SystemStatusRefreshSystemStatusesMutation } from "./__generated__/SystemStatusRefreshSystemStatusesMutation.graphql";
import { SystemStatusResetSensorsMutation } from "./__generated__/SystemStatusResetSensorsMutation.graphql";
import { SystemStatusSilenceAlarmMutation } from "./__generated__/SystemStatusSilenceAlarmMutation.graphql";
import { SystemStatusZoneStatusesRefreshMutation } from "./__generated__/SystemStatusZoneStatusesRefreshMutation.graphql";
import { SystemStatus_controlSystem$key } from "./__generated__/SystemStatus_controlSystem.graphql";
import { SystemStatus_dealer$key } from "./__generated__/SystemStatus_dealer.graphql";

const SystemStatusGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, minmax(0, min-content));
  grid-template-areas: "arming" "zone-status" "doors";

  grid-gap: 1.6rem;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, minmax(0, min-content));
    grid-template-areas: "arming zone-status" "doors doors";
  }
`;

const SystemStatusGridArming = styled.div`
  grid-area: arming;
`;
const SystemStatusGridZoneStatus = styled.div`
  grid-area: zone-status;
`;

const SystemStatusGridDoors = styled.div`
  grid-area: doors;
`;

const ZoneStatusTheme = {
  primary600: DaColors.Primary600,
};

const ArmingWidgetTheme = {
  primary600: DaColors.Primary,
  primary500: DaColors.Primary,
  primary: DaColors.Primary,
  warning: DaColors.WarningBase,
  pending: DaColors.Neutral500,
  danger: DaColors.Failure500,
  grayDark: DaColors.DarkGray,
  grayAccent: DaColors.DarkGray,
  grayLight: DaColors.Neutral200,
  secondaryTextColor: DaColors.Black,
  trueWhite: DaColors.Primary50,
  sliderOnColor: DaColors.Primary,
  sliderOffColor: DaColors.White,
  buttonPrimaryColor: DaColors.Primary,
  buttonDisabledColor: DaColors.Neutral50,
  zebraDark: DaColors.Neutral200,
  sliderDarkText: DaColors.Neutral900,
  grayMedium: DaColors.Black,
  disabledGray: "hsl(214deg 24% 94%)",
  sliderHandleBorder: "hsl(0deg 0% 88%)",
  sliderBackground: DaColors.Neutral200,
};

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{ fallback: (error: any) => React.ReactNode }>,
  { error: any }
> {
  state = { error: null };
  componentDidCatch(error: any) {
    this.setState({ error });
  }
  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;
    if (error) {
      return fallback(this.state);
    }
    return children;
  }
}

export default function SystemStatusRoot(props: any) {
  const environment = React.useMemo(
    () => props.RelayService.getEnvironment(),
    [props.RelayService]
  );

  const timezoneOffset = new Date().getTimezoneOffset() / 60;
  const date = new Date();
  function getISOStringWithoutSecsAndMillisecs1(date: Date) {
    const dateAndTime = date.toISOString().split("T");
    const time = dateAndTime[1].split(":");
    return dateAndTime[0] + "T" + time[0] + ":" + time[1];
  }
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <LanguageProvider>
        <ThemeProvider theme={defaultTheme}>
          <ErrorBoundary fallback={() => <RedirectTo404 />}>
            <React.Suspense fallback={<GenericSuspenseFallback />}>
              <AlertsContextProvider $rootScope={props.$rootScope}>
                <SystemStatus
                  customerId={props.$state.params.customer_id}
                  panelId={props.$state.params.panel_id}
                />
              </AlertsContextProvider>
            </React.Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </LanguageProvider>
    </RelayEnvironmentProvider>
  );
}

const refreshZoneStatusesMutation = graphql`
  mutation SystemStatusZoneStatusesRefreshMutation($id: ID!) {
    refreshZoneStatuses(id: $id) {
      ... on RefreshZoneStatusesSuccessPayload {
        controlSystem {
          ...ZoneStatuses_controlSystem
        }
      }
      ... on RefreshZoneStatusesError {
        type
      }
    }
  }
`;

export const SystemStatus = (props: {
  customerId: string;
  panelId: string;
}) => {
  const data = useLazyLoadQuery<SystemStatusQuery>(
    graphql`
      query SystemStatusQuery($id: ID!) {
        node(id: $id) {
          ... on ControlSystem {
            id
            ...SystemStatus_controlSystem
            customer {
              dealer {
                ...SystemStatus_dealer
              }
            }
          }
        }
      }
    `,
    {
      id: idAsString(toControlSystemId(props.panelId)),
    }
  );

  if (data.node?.id && data.node.customer?.dealer) {
    return (
      <ValidSystemStatus
        controlSystem={data.node}
        dealer={data.node.customer?.dealer}
        panelId={props.panelId}
      />
    );
  } else {
    return <h2>test</h2>;
  }
};

function ValidSystemStatus(props: {
  panelId: string;
  controlSystem: SystemStatus_controlSystem$key;
  dealer: SystemStatus_dealer$key;
}) {
  const data = useFragment(
    graphql`
      fragment SystemStatus_controlSystem on ControlSystem {
        id
        name
        timezoneOffset
        isXf
        isXt
        isXr
        isTMSentry
        isDualCom
        zones {
          id
          isFaulted
          canBypass
          status
          lockdownEnabled
          number
          area {
            id
            number
          }
          ...Zone_zone
        }
        doorsConnection {
          nodes {
            id
          }
        }
        ...ZoneStatuses_controlSystem
        ...ArmingWidget_controlSystem
        ...StatusWidget_controlSystem
        ...DoorListFragment_controlSystem
        ...SystemTestButton_controlSystem
        ...StatusBadgeFragment
      }
    `,
    props.controlSystem
  );
  const dealerData = useFragment(
    graphql`
      fragment SystemStatus_dealer on Dealer {
        id
        centralStationAutomationIntegrations {
          id
        }
        vernaculars {
          original
          replacement
        }
      }
    `,
    props.dealer
  );

  const systemReplacement = dealerData.vernaculars.find(
    (v) => v?.original === "systems"
  )?.replacement;

  const showAlert = useShowAlert();

  const [commitRefreshZoneStatusesMutation, refreshingZoneStatuses] =
    useMutation<SystemStatusZoneStatusesRefreshMutation>(
      refreshZoneStatusesMutation
    );

  const refreshZoneStatuses = () => {
    commitRefreshZoneStatusesMutation({
      variables: {
        id: data.id,
      },
      onCompleted: (result) => {
        if (
          result.refreshZoneStatuses.type &&
          result.refreshZoneStatuses.type === "UNABLE_TO_REFRESH_ZONE_STATUSES"
        ) {
          showAlert({
            type: "error",
            text: "unable to refresh zone statuses",
          });
        }
      },
    });
  };

  const [
    refreshAreaInformationsAndArmedStatus,
    isRefreshingAreaInformationsAndArmedStatus,
  ] = useMutation<SystemStatusRefreshAreaInformationsAndArmedStatusMutation>(
    graphql`
      mutation SystemStatusRefreshAreaInformationsAndArmedStatusMutation(
        $systemId: ID!
      ) {
        refreshAreaInformationsAndArmedStatus(systemId: $systemId) {
          ... on RefreshAreaInformationsAndArmedStatusSuccessPayload {
            controlSystem {
              ...ArmingWidget_controlSystem
            }
          }
          ... on Error {
            type
          }
        }
      }
    `
  );

  const refreshAreas = () => {
    refreshAreaInformationsAndArmedStatus({
      variables: {
        systemId: idAsString(toControlSystemId(props.panelId)),
      },
      onCompleted: (response) => {
        if (response.refreshAreaInformationsAndArmedStatus?.type) {
          showAlert({
            type: "error",
            text: "Unable to Retrieve areas",
          });
        }
      },
    });
  };

  const [resetSensors, isResettingSensors] =
    useMutation<SystemStatusResetSensorsMutation>(
      graphql`
        mutation SystemStatusResetSensorsMutation($id: String!) {
          resetSensors(id: $id) {
            ... on ResetSensorsSuccessPayload {
              __typename
            }
            ... on Error {
              type
            }
          }
        }
      `
    );

  const resetTheSensors = () => {
    resetSensors({
      variables: {
        id: props.panelId,
      },
      onCompleted: () => {
        showAlert({
          type: "success",
          text: "Sensor Reset Sent",
        });
      },
    });
  };

  const [silenceAlarm, isSilenceingAlarm] =
    useMutation<SystemStatusSilenceAlarmMutation>(
      graphql`
        mutation SystemStatusSilenceAlarmMutation($id: String!) {
          silenceAlarm(id: $id) {
            ... on SilenceAlarmSuccessPayload {
              __typename
            }
            ... on Error {
              type
            }
          }
        }
      `
    );

  const silenceTheAlarm = () => {
    silenceAlarm({
      variables: {
        id: props.panelId,
      },
      onCompleted: () => {
        showAlert({
          type: "success",
          text: "Alarm Silence Sent",
        });
      },
    });
  };

  const [lockdown, isLockingdown] = useMutation<SystemStatusLockdownMutation>(
    graphql`
      mutation SystemStatusLockdownMutation($systemId: ID!) {
        lockdown(systemId: $systemId) {
          ... on LockdownMutationSuccessResponse {
            controlSystem {
              ...DoorListFragment_controlSystem
            }
          }
          ... on LockdownMutationFailedResponse {
            error {
              type
            }
          }
        }
      }
    `
  );

  const lockdownPanel = () => {
    lockdown({
      variables: {
        systemId: props.panelId,
      },
      onCompleted: () => {
        showAlert({
          type: "success",
          text: "Lockdown Sent",
        });
      },
    });
  };

  //use this to trigger the onclick event of the test connection button whose modal is implemented in angular
  const testTheConnection = () => {
    let clickEvent = new Event("click");
    document
      .getElementsByClassName("btn-testConn")[0]
      .dispatchEvent(clickEvent);
  };

  const refreshDoorsMutation = graphql`
    mutation SystemStatusDoorListRefreshMutation($id: ID!) {
      refreshDoors(systemId: $id) {
        ... on RefreshDoorsMutationSuccessResponse {
          controlSystem {
            ...DoorListFragment_controlSystem
          }
        }
        ... on RefreshDoorsMutationFailedResponse {
          error {
            type
          }
        }
      }
    }
  `;
  const [refreshDoors, refreshingDoors] =
    useMutation<SystemStatusDoorListRefreshMutation>(refreshDoorsMutation);
  const refreshTheDoors = () => {
    refreshDoors({
      variables: {
        id: data.id,
      },
      onCompleted: (response) => {
        if (response.refreshDoors.error) {
          showAlert({
            type: "error",
            text: "Unable to Retrieve Door Statuses",
          });
        }
      },
    });
  };

  const refreshLxBusMutation = graphql`
    mutation SystemStatusLxBusRefreshMutation($id: String!) {
      refreshLxBus(id: $id) {
        ... on RefreshLxBusSuccessPayload {
          __typename
        }
        ... on RefreshLxBusError {
          type
        }
      }
    }
  `;
  //referesh must be run to update the current bus status
  const [refreshLxBus, refreshingLxBus] =
    useMutation<SystemStatusLxBusRefreshMutation>(refreshLxBusMutation);
  const refreshTheLxBus = () => {
    showAlert({
      type: "warning",
      text: "Retrieving LxBus Diagnostics",
    });
    refreshLxBus({
      variables: {
        id: props.panelId,
      },
      onCompleted: (response) => {
        if (response.refreshLxBus.type) {
          showAlert({
            type: "error",
            text: "Unable to Retrieve LX Bus",
          });
          setLoadDiagnosticModalOpen(false);
        } else {
          setLoadDiagnosticModalOpen(false);
          setDiagnosticModalOpen(true);
        }
      },
    });
  };

  const refreshSystemStatusesMutation = graphql`
    mutation SystemStatusRefreshSystemStatusesMutation($id: ID!) {
      refreshSystemStatuses(systemId: $id) {
        __typename
        ... on RefreshSystemStatusesSuccessPayload {
          controlSystem {
            ...StatusWidget_controlSystem
          }
        }

        ... on RefreshSystemStatusesErrorPayload {
          type
        }
      }
    }
  `;
  const [refreshSystemStatuses, refreshingSystemStatuses] =
    useMutation<SystemStatusRefreshSystemStatusesMutation>(
      refreshSystemStatusesMutation
    );
  const refreshTheSystemStatuses = () => {
    refreshSystemStatuses({
      variables: {
        id: data.id,
      },
      onCompleted: ({ refreshSystemStatuses }) => {
        if (
          refreshSystemStatuses.__typename ===
          "RefreshSystemStatusesErrorPayload"
        ) {
          showAlert({
            type: "error",
            text: `Unable to Refresh ${systemReplacement ?? "System"} Statuses`,
          });
        }
      },
    });
  };
  React.useEffect(() => {
    if (data.isXf) {
      refreshTheSystemStatuses();
    } else {
      refreshAreas();
    }
    refreshZoneStatuses();
    if (data.doorsConnection?.nodes.length) {
      refreshTheDoors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const armingRef = React.useRef<HTMLDivElement | null>(null);
  const armingHeight = useBoundingClientRect(armingRef).height;

  const [timeModalOpen, setTimeModalOpen] = React.useState<boolean>(false);

  const [forgiveUserModalOpen, setForgiveUserModalOpen] =
    React.useState<boolean>(false);

  const [diagnosticModalOpen, setDiagnosticModalOpen] =
    React.useState<boolean>(false);
  const [diagnosticLoadModalOpen, setLoadDiagnosticModalOpen] =
    React.useState<boolean>(false);

  const dropdownOptions = [];

  // Do not add Alarm Silence and Sensor Reset on XF systems
  if (!data.isXf) {
    dropdownOptions.push(
      {
        icon: <DmpIcon icon="alarm_bell_2" />,
        message: "Alarm Silence",
        onClick: silenceTheAlarm,
      },
      {
        icon: <ResetIcon />,
        message: "Sensor Reset",
        onClick: resetTheSensors,
      }
    );
  }

  dropdownOptions.push({
    icon: <PanelCommunicationIcon />,
    message: "Test Connection",
    onClick: testTheConnection,
  });

  // Add Forgive User on XR systems
  if (data.isXr) {
    dropdownOptions.push({
      icon: <DmpIcon icon="user" />,
      message: "Forgive User",
      onClick: () => setForgiveUserModalOpen(true),
    });
  }

  // Do not add LxBus on XT or TMSentry systems as they do not have one
  if (!data.isXt && !data.isTMSentry) {
    dropdownOptions.push({
      icon: <DmpIcon icon="installer" />,
      message: "LX Bus Diagnostics",
      onClick: () => {
        setLoadDiagnosticModalOpen(true);
        refreshTheLxBus();
      },
    });
  }

  dropdownOptions.push(
    {
      icon: <TimeClockIcon />,
      message: "Set Time",
      onClick: () => {
        setTimeModalOpen(true);
      },
    },
    {
      icon: <CycleIcon />,
      message: "Refresh",
      onClick: () => {
        refreshZoneStatuses();
        if (!(data.isTMSentry || data.isDualCom)) {
          refreshTheDoors();
        }
        if (data.isXf) {
          refreshTheSystemStatuses();
        } else {
          refreshAreas();
        }
      },
    }
  );

  return (
    <>
      <Flex.BstrapRow>
        <div className="page-header">
          <div className="page-header__left">
            <div className="page-header__title">
              {data.name} - {systemReplacement ?? "System"} Status
              {refreshingLxBus ? (
                <div
                  style={{
                    fontSize: "20px",
                    paddingLeft: "10px",
                  }}
                >
                  <LoadingSpinner />
                </div>
              ) : null}
            </div>
            <div className="page-header__right"></div>
            {dealerData.centralStationAutomationIntegrations.length > 0 ? (
              <StatusBadge controlSystem={data} />
            ) : null}
          </div>
          <div className="page-header__right">
            <DefaultMoreButton items={dropdownOptions} />
            {timeModalOpen ? (
              <TimeSetModal
                timezoneOffset={data.timezoneOffset}
                panelId={props.panelId}
                setModalOpen={setTimeModalOpen}
                systemReplacement={systemReplacement}
              />
            ) : null}
            {forgiveUserModalOpen ? (
              <ForgiveUserModal
                timezoneOffset={data.timezoneOffset}
                panelId={props.panelId}
                setModalOpen={setForgiveUserModalOpen}
              />
            ) : null}
            {diagnosticModalOpen ? (
              <DiagnosticModal
                panelId={props.panelId}
                setModalOpen={setDiagnosticModalOpen}
              />
            ) : null}
            {diagnosticLoadModalOpen ? (
              <DiagnosticLoadModal setModalOpen={setLoadDiagnosticModalOpen} />
            ) : null}

            <SystemTestButton
              controlSystem={data}
              systemReplacement={systemReplacement}
            />
          </div>
        </div>
      </Flex.BstrapRow>

      <Flex.BstrapRow>
        <SystemStatusGrid>
          {data.isXf ? (
            <SystemStatusGridArming>
              <ThemeProvider theme={defaultTheme}>
                <StatusWidget
                  ref={armingRef}
                  controlSystem={data}
                  isLoading={refreshingSystemStatuses}
                />
              </ThemeProvider>
            </SystemStatusGridArming>
          ) : (
            <SystemStatusGridArming>
              <ThemeProvider theme={ArmingWidgetTheme}>
                <ArmingWidget
                  ref={armingRef}
                  controlSystem={data}
                  isLoading={isRefreshingAreaInformationsAndArmedStatus}
                  onRefreshError={() =>
                    showAlert({
                      type: "error",
                      text: "Unable to Retrieve Areas",
                    })
                  }
                />
              </ThemeProvider>
            </SystemStatusGridArming>
          )}

          <SystemStatusGridZoneStatus>
            <ThemeProvider theme={ZoneStatusTheme}>
              <ZoneStatuses
                height={armingHeight}
                controlSystem={data}
                refreshing={refreshingZoneStatuses}
                refreshZoneStatuses={refreshZoneStatuses}
                globalControls={[]}
              />
            </ThemeProvider>
          </SystemStatusGridZoneStatus>
          {!data.isXf ? (
            <SystemStatusGridDoors>
              <ThemeProvider theme={defaultTheme}>
                <React.Suspense fallback={"Loading"}>
                  <DoorList
                    isRefreshing={refreshingDoors}
                    controlSystem={data}
                  />
                </React.Suspense>
              </ThemeProvider>
            </SystemStatusGridDoors>
          ) : null}
        </SystemStatusGrid>
      </Flex.BstrapRow>
    </>
  );
}
