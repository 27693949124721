import {
  ChangeEvent,
  ChangeEventHandler,
  DetailedHTMLProps,
  FocusEvent,
  FocusEventHandler,
  FormEvent,
  FormEventHandler,
  ForwardedRef,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useConceptIsActive,
  useSetActiveConcept,
} from "../common/ActiveConceptContext";
import {
  addToChangedProgrammingConceptsIfChanged,
  useSetChangedProgrammingConcepts,
} from "../common/ChangedProgrammingConceptsContext";
import { useSetFieldValidity } from "../common/InvalidFieldsContext";
import { useIsNew } from "../common/IsNewContext";
import { useProgrammingConceptFormListItemIdContext } from "../common/ProgrammingConceptFormListItemIdContext";
import { useProgrammingConceptIdContext } from "../common/ProgrammingConceptIdContext";
import {
  useListItemIsSelected,
  useSetSelectedListItem,
} from "../common/SelectedListItemContext";

type ElementProps<TElement extends HTMLInputElement | HTMLSelectElement> =
  DetailedHTMLProps<InputHTMLAttributes<TElement>, TElement>;

export function useFieldChangesAndValidity<
  TElement extends HTMLInputElement | HTMLSelectElement
>(props: {
  id: string;
  value: ElementProps<TElement>["value"];
  type?: ElementProps<TElement>["type"];
  checked?: ElementProps<TElement>["checked"];
  ref?: ForwardedRef<TElement | null>;
  onChange?: ChangeEventHandler<TElement>;
  onFocus?: FocusEventHandler<TElement>;
  onBlur?: FocusEventHandler<TElement>;
  onInvalid?: FormEventHandler<TElement>;
  getValidationMessage?: (input: TElement) => string;
}) {
  const {
    id,
    type,
    value,
    checked,
    ref,
    onChange,
    onFocus,
    onBlur,
    onInvalid,
  } = props;

  const trackedValue = type === "checkbox" ? checked : value;
  const originalValue = useRef(trackedValue).current;
  const renderCount = useRef(0);
  const programmingConceptId = useProgrammingConceptIdContext();
  const listItemId = useProgrammingConceptFormListItemIdContext();
  const setFieldValidity = useSetFieldValidity(
    programmingConceptId,
    listItemId
  );
  const setChangedProgrammingConcepts = useSetChangedProgrammingConcepts();
  const programmingConceptIsActive = useConceptIsActive(programmingConceptId);
  const listItemIsSelected = useListItemIsSelected(listItemId);
  const setActiveProgrammingConcept = useSetActiveConcept();
  const setSelectedListItem = useSetSelectedListItem();
  const isNew = useIsNew();
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);

  const input = useRef<TElement | null>(null);

  renderCount.current += 1;

  useEffect(() => {
    if (input.current) {
      const validationMessage = props.getValidationMessage
        ? props.getValidationMessage(input.current)
        : "";
      input.current.setCustomValidity(validationMessage);
      setFieldValidity(input.current);
    }
  });

  useEffect(() => {
    if (!isNew && renderCount.current > 1) {
      setChangedProgrammingConcepts(
        addToChangedProgrammingConceptsIfChanged({
          id,
          originalValue,
          value: trackedValue,
          programmingConceptId,
          listItemId,
        })
      );
    }
  }, [
    id,
    programmingConceptId,
    listItemId,
    originalValue,
    trackedValue,
    setChangedProgrammingConcepts,
    isNew,
  ]);

  function handleChange(event: ChangeEvent<TElement>) {
    //we don't current do anything here, but we left handleChange in if needed in the future
    if (onChange) {
      onChange(event);
    }
  }

  function handleFocus(event: FocusEvent<TElement>) {
    // Prevents the interuption of a select when you click it but still shows the error when saving (shouldAutoFocus)
    if (event.target.type !== "select-one" || shouldAutoFocus) {
      setTimeout(() => {
        event.target.reportValidity();
      }, 0);
    }
    if (onFocus) {
      onFocus(event);
    }
  }

  function handleBlur(event: FocusEvent<TElement>) {
    if (shouldAutoFocus) {
      setShouldAutoFocus(false);
    }

    if (onBlur) {
      onBlur(event);
    }
  }

  function handleInvalid(event: FormEvent<TElement>) {
    if (listItemId) {
      //if we have a list item id, we are in a list, so we need to set the selected list item even if we it is already selected
      //this prevents an error where send is clicked when the item is already selected and it jumped to the first item in the list
      //DA-4383
      setSelectedListItem(listItemId);
    }
    if (!programmingConceptIsActive) {
      setActiveProgrammingConcept(programmingConceptId);
    }

    setShouldAutoFocus(true);

    if (onInvalid) {
      onInvalid(event);
    }
  }

  return {
    id,
    type,
    value,
    checked,
    ref: (node: TElement | null) => {
      input.current = node;

      if (typeof ref === "function") {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    },
    onChange: handleChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
    onInvalid: handleInvalid,
    autoFocus: shouldAutoFocus,
    key: shouldAutoFocus.toString(),
  };
}
